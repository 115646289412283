import ko from 'knockout';
import JobDetailsAbstractViewModel from 'job-details/JobDetailsAbstractViewModel';
import router from 'app/model/router';
import cxEvents from 'cx/config/events';
import deviceDetector from 'core/device-detector/deviceDetector';
import i18n from 'core/i18n/i18n';

export default class JobDetailsMoheganViewModel extends JobDetailsAbstractViewModel {

    constructor(params = {}) {
        super(params);
        this.isIOS = deviceDetector.isIOS();
        this.isFooterInViewport = ko.observable(false);
        this.shouldSwitchViewButtonBeFocused = ko.observable(false);
        this.jobTitleAccessibilitySuffix = `"${i18n('job-details.page-title')}"`;

        this._isFooterInViewportChanged =
            cxEvents.cxFooterInViewportChanged.add(isViewport => this.isFooterInViewport(isViewport));

        this.requisitionId = ko.pureComputed(() =>
            this._getRequisitionId(),
        );
    }

    toggleView() {
        router
            .go(this.isJobPreview() ? 'job-full-view' : 'job-preview', {
                jobId: this.jobId(),
            })
            .then(() => {
                this.shouldSwitchViewButtonBeFocused(true);
                this._updatePageTitle();
            });
    }

    closeOnSwipe() {
        setTimeout(() => this.closeJobDetails(), 300);
    }

    dispose() {
        super.dispose();

        this._isFooterInViewportChanged.detach();
    }

    _getRequisitionId() {
        return this.job() ? this.job().requisitionId : undefined;
    }

}