import ko from 'knockout';
import $ from 'jquery';
import 'jqueryui-amd/core';
import 'jqueryui-amd/widget';
import 'jqueryui-amd/widgets/mouse';
import 'jquery-ui-widgets/autocomplete';

/**
 * Disables events on ui-autocomplete element and focuses on the second suggestion
 *
 * @example
 * <input type='search' data-bind='autocomplete: {model: model, source: suggestions}, staticAutocomplete'/>
 */
ko.bindingHandlers.staticAutocomplete = {
    after: ['autocomplete'],
    init(element) {
        const $input = $(element);

        $input.autocomplete('search')
            .on('autocompleteopen', () => {
                const $autocomplete = $input.next('.ui-autocomplete');

                $autocomplete.off('mouseenter .ui-menu-item').off('click .ui-menu-item');
                $autocomplete.find('.ui-menu-item').eq(2).addClass('ui-state-focus');
            });
    },
};