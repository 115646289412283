import { observable, pureComputed } from 'knockout';
import router from 'app/model/router';
import geolocationSuggester from 'search/module/search-box/model/geolocationSuggester';
import searchEvents from 'search/config/events';
import i18n from 'core/i18n/i18n';

const RADIUS_UNITS = {
    mi: i18n('location-bar.dropdown.radius-unit.miles'),
    km: i18n('location-bar.dropdown.radius-unit.kilometers'),
};

class GeolocationSearchViewModel {

    constructor(params) {
        this.location = params.location || observable({});
        this.exactMatch = params.exactMatch || observable({});
        this.geolocation = geolocationSuggester;
        this.clearButtonVisible = observable();
        this.noResults = observable(false);
        this.radius = observable(null);
        this.radiusUnit = observable(null);

        this.inputPlaceholder = observable('');

        this.submitAriaLabel = pureComputed(
            () => i18n('location-bar.dropdown.submit-button-description', {
                location: this.locationAsText(),
                radius: this.radius(),
                unit: this.radiusUnit(),
            }),
        );

        searchEvents.query.basicSearch.add(() => {
            const searchParams = this.activeSearchParams();

            this.clearButtonVisible(searchParams.location);
        });

        this.activeSearchParams = pureComputed(() => router.routeParams().query || {}, this);

        this.isLocationLevelSet = pureComputed(() =>
            this.location().level || this.exactMatch().level || this.location().isGeolocation);

        this.locationAsText = pureComputed({
            read() {
                return this.location().geolocationLabel || this.location().label;
            },
            write(text) {
                this.geolocation.abort();

                this.location({
                    label: text,
                });
            },
            owner: this,
        });

        this.isGeolocation = pureComputed(() => this.location().isGeolocation === true);

        this.locationSub = this.location.subscribe((location) => {
            const locationParam = location.level || location.isGeolocation ? location : {};

            searchEvents.query.params.dispatch({
                location: locationParam,
            });

            this.exactMatch({});
        });

        this.performSearch = (shouldRedirect) => {
            if (this.exactMatch().level) {
                this.location(this.exactMatch());
            }

            searchEvents.query.basicSearch.dispatch(shouldRedirect);
        };

        searchEvents.query.params.add(({ radius, radiusUnit }) => {
            if (radius && radiusUnit) {
                this.radius(radius);
                this.radiusUnit(RADIUS_UNITS[radiusUnit.toLowerCase()] || '');
            }
        });

        searchEvents.query.params.dispatch({
            modeLoaded: true,
        });

        this.searchGeolocation();
    }

    async searchGeolocation() {
        this.noResults(false);

        this.inputPlaceholder('location-bar.dropdown.geolocation-in-progress');

        try {
            const { name, latitude, longitude } = await geolocationSuggester.query();

            this.location({
                latitude,
                longitude,
                label: name,
                isGeolocation: true,
            });
        } catch (error) {
            if (error instanceof Error) {
                console.error(error);
            } else {
                this.inputPlaceholder('');
            }
        }
    }

    dispose() {
        this.locationSub.dispose();
    }

}

export default GeolocationSearchViewModel;
