import { observable, pureComputed } from 'knockout';
import router from 'app/model/router';
import appConfig from 'app/model/config';
import customContentService from 'cx/module/custom-content/service/customContent';
import { CUSTOM_SPLASH_PAGE } from 'cx/module/custom-content/enums/pageTypes';

export default class SplashPageViewModel {

    constructor() {
        const { pageCode: splashPageCode } = appConfig.templates[CUSTOM_SPLASH_PAGE] ?? {};

        this.splashPage = observable(null);
        this.isLoading = observable(true);
        this.isPageDisplayBoxed = observable(true);
        this.isPreview = pureComputed(() => router.routeParams().query?.preview);

        this.bodyClassName = 'video-fullscreen';

        this._loadPage(splashPageCode);
    }

    _getPage(pageCode) {
        if (!pageCode) {
            return Promise.reject();
        }

        if (this.isPreview()) {
            return customContentService.getDraft(pageCode);
        }

        return customContentService.get(pageCode);
    }

    _loadPage(pageCode) {
        return this._getPage(pageCode)
            .then((page) => {
                this.splashPage(page);
                this.isPageDisplayBoxed(page.params.display === 'boxed');
                this.isLoading(false);
            }).catch(() => {
                this.isLoading(false);
            });
    }

}
