import ko from 'knockout';
import router from 'app/model/router';
import searchEvents from 'search/config/events';
import localeUnitOfLength from 'search/model/localeUnitOfLength';
import { MODES } from 'search/module/location/module/mode-menu/service/mode';

const DEFAULT_RADIUS = 25;
const DEFAULT_RADIUS_UNIT = localeUnitOfLength.getShort(navigator.language.toLowerCase()) || 'KM';

export default class RadiusSearchMoheganViewModel {

    constructor() {
        this.location = ko.observable({});

        this.radius = ko.observable().extend({
            notify: 'always',
        });

        this.radiusUnit = ko.observable().extend({
            notify: 'always',
        });

        this.forceEnabled = ko.observable(false);
        this.modeLoaded = ko.observable();
        this.activeSearchParams = ko.pureComputed(() => router.routeParams().query || {}, this);

        this.isRadiusDisabled = ko.pureComputed(() =>
            !this.forceEnabled() && this.location().level !== 'city' && !this.location().isGeolocation,
        );

        this.searchEventsParamsChangeSignal = searchEvents.query.params.add((params) => {
            if (params.modeLoaded) {
                this.modeLoaded(params.modeLoaded);
            }

            if (params.location) {
                this.location(params.location);
            }
        });

        this.modeLoadedSub = this.modeLoaded.subscribe(() => {
            searchEvents.query.params.dispatch({
                radius: this.radius(),
                radiusUnit: this.radiusUnit(),
            });

            this.modeLoaded(false);
        });

        this.enableRadiusPanelSignal = searchEvents.radius.enable.add(this.forceEnabled.bind(this));
        this.setRadiusSignal = searchEvents.radius.set.add(this.radius.bind(this));

        this.radiusSub = this.radius.subscribe((radius) => {
            if (radius > 100) {
                this.radius(100);

                return;
            }

            searchEvents.query.params.dispatch({
                radius,
                radiusUnit: this.radiusUnit(),
            });
        });

        this.radiusUnitSub = this.radiusUnit.subscribe((radiusUnit) => {
            searchEvents.query.params.dispatch({
                radius: this.radius(),
                radiusUnit,
            });
        });

        this.activeSearchParamsSub = this.activeSearchParams.subscribe(this._setParamsFromRouter.bind(this));
        this.locationSub = this.location.subscribe(this._setInitialRadiusForLocation.bind(this));

        this._setParamsFromRouter();
        this._setInitialRadiusForZipCode();
    }

    _setInitialRadiusForLocation(location) {
        if (location.value || (location.isGeolocation && location.label)) {
            this.radius(DEFAULT_RADIUS);
        }
    }

    _setInitialRadiusForZipCode() {
        const { query } = router.routeParams();

        if (!this.radius() && query && query.mode === MODES.ZIPCODE) {
            this.radius(DEFAULT_RADIUS);
            this.forceEnabled(true);
        }
    }

    _setParamsFromRouter() {
        const searchParams = this.activeSearchParams();

        this.location({
            label: searchParams.location,
            level: searchParams.locationLevel,
            latitude: searchParams.latitude,
            longitude: searchParams.longitude,
            isGeolocation: searchParams.latitude != null && searchParams.longitude != null,
        });

        this.radius(searchParams.radius || DEFAULT_RADIUS);
        this.radiusUnit(searchParams.radiusUnit || DEFAULT_RADIUS_UNIT);
    }

    dispose() {
        this.radiusSub.dispose();
        this.modeLoadedSub.dispose();
        this.radiusUnitSub.dispose();
        this.activeSearchParamsSub.dispose();
        this.locationSub.dispose();
        this.enableRadiusPanelSignal.detach();
        this.searchEventsParamsChangeSignal.detach();
        this.setRadiusSignal.detach();
    }

}
