import CandidateSelfServiceViewModel from 'cx/module/candidate-self-service/CandidateSelfServiceViewModel';
import CandidateSelfServiceShortTokenViewModel from 'cx/module/candidate-self-service/CandidateSelfServiceShortTokenViewModel';
import router from 'app/model/router';

export function createViewModel() {
    if (router.routeParams().token) {
        return new CandidateSelfServiceShortTokenViewModel();
    }

    return new CandidateSelfServiceViewModel();
}