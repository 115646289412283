import { generateDefaultJobAttributes, generateJobFlexFields } from 'cx/module/job-details/service/jobAttributes';

export default class JobDetailsSummaryViewModel {

    constructor({ job }) {
        this.attributesList = [
            ...Object.values(generateDefaultJobAttributes(job())),
            ...generateJobFlexFields(job()),
        ];
    }

}