import ko from 'knockout';
import scrollbar from 'job-details/component/scrollbar/scrollbar';


/**
 * Adds custom scrollbar to container (only on windows desktop platforms).
 * Layout and design of the scrollbar is defined by '.ss-scroll' and '.ss-scrollbar-wrapper' classes.
 *
 * @example
 * <div class="job" id="job" data-bind="scrollable">
 */
const userAgent = navigator.userAgent.toLowerCase();

const isWindowsDesktop = navigator.platform.indexOf('Win') !== -1
    && !(userAgent.indexOf('windows nt') !== -1 && userAgent.indexOf('touch') !== -1)
    && userAgent.indexOf('windows phone') === -1;

if (isWindowsDesktop) {
    ko.bindingHandlers.scrollable = {
        init(element) {
            scrollbar.initializeScrollbar(element);
        },
    };
} else {
    document.getElementsByTagName('body')[0].className += ' is-not-windows';
}