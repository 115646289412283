import 'job-details/binding/scrollable';
import 'job-details/component/scrollbar/scrollbar';
import 'job-details/component/copy-link/component';
import 'job-details/module/media-player/config/module';
import 'job-details/module/media-tiles/config/module';
import 'job-details/module/job-details-summary/config/module';
import 'search/module/search-results/component/search-similar/component';
import '../binding/generateGoogleJobSearchTags';
import './routing';
import './templates';

import { components } from 'knockout';

import JobDetailsViewModel from '../JobDetailsViewModel';
import jobDetailsTemplate from '../job-details.html';

components.register('job-details', {
    viewModel: JobDetailsViewModel,
    template: jobDetailsTemplate,
});
