import ko from 'knockout';
import $ from 'jquery';

const SCROLL_EVENT = 'scroll.cx.cssOnScrollDown';

function isVisible($element) {
    return $element.css('display') !== 'none' && $element.css('visibility') !== 'hidden';
}

/**
 * Applies given CSS class when browser's viewport reaches specified offset from top.
 *
 * @param {string} class - name of CSS class to be applied to the element
 * @param {number} offset - value of top offset
 * @param {boolean} useBottom - whether to adjust element's bottom styling setting or not
 *
 * @example
 * <div data-bind="cssOnScrollDown: { class: 'offer-actions--visible', offset: 50, useBottom: true }"></div>
 */
ko.bindingHandlers.cssOnScrollDown = {
    init(element, bindingParams) {
        const $element = $(element);
        const $window = $(window);
        const cssClass = bindingParams().class;
        const { useBottom } = bindingParams();
        let offset = bindingParams().offset || 0;

        if (!offset) {
            $element.addClass(cssClass);
        }

        function toggleClass(hasMovedDown) {
            if (!hasMovedDown) {
                $element.removeClass(cssClass);

                return;
            }

            if (!$element.hasClass(cssClass)) {
                $element.addClass(cssClass);
            }
        }

        let bottomValue;

        function calcInitialBottomValue() {
            if (!bottomValue) {
                bottomValue = parseInt(window.getComputedStyle(element).getPropertyValue('bottom'), 10);
            }
        }

        function adjustBottom(hasMovedDown) {
            let currentBottomValue = '';

            if (useBottom && hasMovedDown) {
                calcInitialBottomValue();
                currentBottomValue = $window.scrollTop() + bottomValue;
            }

            $element.css('bottom', currentBottomValue);
        }

        function onScroll() {
            if (typeof offset === 'string') {
                offset = ($window.height() / 100) * parseInt(offset, 10);
            }

            const hasMovedDown = ($window.scrollTop() >= offset) && isVisible($element);

            toggleClass(hasMovedDown);
            adjustBottom(hasMovedDown);
        }

        $window.on(SCROLL_EVENT, onScroll);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            $window.off(SCROLL_EVENT, onScroll);
        });
    },
};