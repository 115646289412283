import { observableArray } from 'knockout';
import mapping from 'knockout-mapping';
import storageService from 'core/storage/localStorage';
import userTracking from 'cx/service/userTracking';

const KEY = 'recent-searches';
const recentSearches = observableArray();

export default class RecentSearches {

    constructor() {
        try {
            const storageValue = storageService.restore(KEY);

            if (Array.isArray(storageValue)) {
                recentSearches(storageValue);
            } else {
                recentSearches([]);
            }
        } catch (error) {
            console.error(error);
        }
    }

    getAll() {
        return recentSearches();
    }

    saveSearch(params) {
        const searchParams = RecentSearches.removeEmptyFields(params);
        const { keyword, location, locationLevel } = searchParams;

        if (!keyword && !location) {
            return;
        }

        const recentSearchItem = RecentSearches.getRecentSearchItem(recentSearches(), searchParams);

        if (recentSearchItem) {
            recentSearches.remove(recentSearchItem);
        }

        if (locationLevel && locationLevel !== 'city') {
            delete searchParams.radius;
        }

        recentSearches.push(searchParams);

        userTracking.trackRequisitionSearch(searchParams);

        storageService.store(KEY, mapping.toJS(recentSearches));
    }

    static removeEmptyFields(recentSearch) {
        return Object.keys(recentSearch)
            .filter(key => recentSearch[key])
            .reduce((accumulator, key) =>
                ({ [key]: recentSearch[key], ...accumulator }), {});
    }

    static getRecentSearchItem(searches, recentSearch) {
        return searches
            .find(search =>
                recentSearch.keyword === search.keyword
                && recentSearch.location === search.location
                && recentSearch.radius === search.radius
                && recentSearch.radiusUnit === search.radiusUnit);
    }

}
