import ko from 'knockout';

ko.bindingHandlers.calculateViewportHeight = {
    init(element) {
        function calculate() {
            const windowHeight = window.innerHeight;

            document.documentElement.style.setProperty('--viewport-height', `${windowHeight}px`);
        }

        calculate();

        window.addEventListener('resize', calculate);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            window.removeEventListener('resize', calculate);
        });
    },
};