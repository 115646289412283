import notificationsInstance from './cxNotifications';

import { components } from 'knockout';

import template from './cx-notifications.html';

components.register('cx-notifications', {
    viewModel: {
        instance: notificationsInstance,
    },
    template,
});
