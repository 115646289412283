import cxHeaderEvents from 'cx/component/cx-header/config/events';
import candidateVerificationEvents from 'candidate-verification/config/events';
import internalUserEvents from 'ce-common/module/internal-user/config/events';
import favouriteEvents from 'favourite/config/events';
import searchEvents from 'search/config/events';
import searchHeaderEvents from 'search/module/search-header/config/events';


cxHeaderEvents.openMenu.add(favouriteEvents.closePanel.dispatch);

candidateVerificationEvents.hitCounterUpdated.add(internalUserEvents.refreshIceRouteParams.dispatch);

favouriteEvents.openPanel.add(cxHeaderEvents.closeMenu.dispatch);

searchEvents.jobDetails.isClosing.add(favouriteEvents.jobDetailsClosed.dispatch);
searchEvents.jobDetails.applyButtonVisibilityChanged.add(cxHeaderEvents.toggleHeader.dispatch);

searchEvents.query.filterSearch.add(() => {
    searchHeaderEvents.filtersActive.dispatch(true);
});

searchEvents.query.basicSearch.add(() => {
    searchHeaderEvents.filtersActive.dispatch(false);
});