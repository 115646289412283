import { observable } from 'knockout';
import appConfig from 'app/model/config';
import searchEvents from 'search/config/events';
import themeEvents from 'cx/module/site-editor/config/events';

export default class CustomFooterViewModel {

    constructor() {
        const { footerMode } = appConfig;

        this.enabled = observable(footerMode === 2 || footerMode === 4);
        this.customHtml = observable(false);

        this.jobDetailsPreviewMode = observable(false);
        this.filterPanelVisible = observable(false);

        if (appConfig.theme) {
            this.customHtml(appConfig.theme.brand.footerHtml);
        }

        this._customFooterUpdatedSignal = themeEvents.customFooterUpdated.add((enabled, html) => {
            this.customHtml(html);
            this.enabled(enabled);
        });

        this._filterPanelToggleSignal = searchEvents.filterPanel.toggle.add(this.filterPanelVisible);
        this.templateExists = Boolean(document.getElementById('custom-footer'));
    }

    dispose() {
        this._customFooterUpdatedSignal.detach();
        this._filterPanelToggleSignal.detach();
    }

}
