import ko from 'knockout';
import screenInfo from 'cx/model/screenInfo';
import cxHeaderEvents from '../cx-header/config/events';

export default class CxHeaderLinksViewModel {

    constructor(params) {
        this.links = ko.pureComputed(() => params.links().map((singleLink) => {
            const link = singleLink;

            link.isOpen = ko.observable(false);

            return link;
        }));

        this.isVertical = screenInfo.isLargeUp;

        this.closeOnRedirect = this.closeOnRedirect.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    open(target) {
        this.links().forEach((link) => {
            link.isOpen(false);
        });

        target.isOpen(true);
    }

    close(link) {
        link.isOpen(false);
    }

    closeOnRedirect() {
        this.links().forEach(link => link.isOpen(false));

        if (!this.isVertical()) {
            cxHeaderEvents.closeMenu.dispatch();
        }

        return true;
    }

    toggle(link) {
        if (link.isOpen()) {
            this.close(link);
        } else {
            this.open(link);
        }
    }

}
