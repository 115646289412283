import { components } from 'knockout';
import metadataConfig from 'cx/module/apply-flow/config/metadata';
import '../component/misc-attachment-list-item/component';
import ViewModel from '../MiscAttachmentsViewModel';
import template from '../misc-attachments.html';

components.register('misc-attachments', { viewModel: ViewModel, template });

metadataConfig.registerBlock('ORA_MISC_DOCUMENTS', {
    component: 'misc-attachments',
});
