const SUPPORTED_URLS = /(https?:\/\/)?(www.)?(player\.vimeo\.com\/video\/|youtube\.com\/embed\/)([A-Za-z0-9_-]*)/i;

export default class VideoViewerViewModel {

    constructor(params) {
        this.url = params.url;

        if (SUPPORTED_URLS.test(this.url())) {
            this.url(`${this.url()}?autoplay=1`);
        }
    }

}