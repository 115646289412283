import 'apply-flow/config/imports';

import '../component/apply-flow-post-summary-thank-you/component';
import '../component/apply-flow-post-pin-verification/component';

import './routing';

import { components } from 'knockout';

import ViewModel from '../ApplyFlowPostViewModel';
import template from 'apply-flow/apply-flow.html';

components.register('apply-flow-post', { viewModel: ViewModel, template });
