import ko from 'knockout';
import router from 'app/model/router';
import tokenService from 'candidate-verification/service/token';
import notificationsService from 'cx/service/notifications';

export default class CampaignUnsubscribeViewModel {

    constructor() {
        this.isUnsubscribed = ko.observable();

        this.verifyToken()
            .then(() => this.isUnsubscribed(true))
            .catch(() => {
                this.isUnsubscribed(true);
                notificationsService.error();
            });
    }

    verifyToken() {
        const { token } = router.routeParams();

        return tokenService.verifyToken(token);
    }

    goToParentRoute() {
        const route = router.route();

        router.go(route.parent.id);
    }

}
