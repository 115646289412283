import ko from 'knockout';
import $ from 'jquery';
import keycodes from 'core/config/keycodes';


/**
 * Add arrow keys handler to header navigation submenus.
 *
 * @param {string} selector - selector for elements that should be focusable
 * @param {boolean} isVertical - true (vertical orientation) / false (horizontal orientation)
 *
 * @example
 * <ul data-bind="loopNavigation: { selector: '.elements-selector', isVertical: isVertical }"></ul>
 */
ko.bindingHandlers.loopNavigation = {
    init(container, accessor) {
        const $container = $(container);
        const { selector } = accessor();

        function getKeys(isVertical) {
            return {
                next: isVertical ? keycodes.LEFT_ARROW : keycodes.UP_ARROW,
                previous: isVertical ? keycodes.RIGHT_ARROW : keycodes.DOWN_ARROW,
            };
        }

        function resolveFocus(event) {
            const $elements = $container.find(selector);
            let index = $elements.index(this);
            const keys = getKeys(accessor().isVertical);
            let eventHandled = true;

            switch (event.which) {
                case keys.next:
                    index = index - 1 < 0 ? $elements.length - 1 : index - 1;
                    break;
                case keys.previous:
                    index = index + 1 > $elements.length - 1 ? 0 : index + 1;
                    break;
                default:
                    eventHandled = false;
            }

            if (eventHandled) {
                event.preventDefault();
                $elements.eq(index).focus();
            }
        }

        $container.on('keydown', selector, resolveFocus);

        ko.utils.domNodeDisposal.addDisposeCallback(container, () => {
            $container.off('keydown', resolveFocus);
        });
    },
};
