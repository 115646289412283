import $ from 'jquery';
import ko from 'knockout';
import debounce from 'core/utils/debounce';

/**
 * Updates value of passed observable based on visibility of the element in a viewport.
 * Whether it considers element visible when top edge of the element is visible, or when the whole
 * element is visible is determined by a parameter passed to the binding. By default it sets observable
 * to true only when whole element is visible on screen.
 *
 * @param {ko.observable} obsv - Observable from ViewModel, whose value will be updated
 * @param {String} edge [top, bottom, both]- parameter that decides which edge will be checked whether it is visible
 *
 * @example
 * When section #no1 is in viewport, CSS class 'is-visible' will be applied to element.
 * When section #no1 is NOT in viewport, CSS class 'is-visible' will be removed from element.
 * Same for section #no2, but only top edge of the section is considered.
 *
 * class ViewModel {
 *     this.isInViewport = ko.observable();
 * }
 *
 * <section id="no1" data-bind="inViewport: isInViewport, css: {'is-visible': isInViewport}"></section>
 * <section id="no2" data-bind="inViewport: {obsv: isInViewport, edge: 'top'},
 *                                           css: {'is-visible': isInViewport"></section>
 */

const EDGES = {
    TOP: 'top',
    BOTTOM: 'bottom',
    BOTH: 'both',
};

const elementsToObserve = [];

let viewportStart = window.pageYOffset;
let viewportEnd = viewportStart + window.innerHeight;

function isInViewPort({ $element, edge }) {
    const elementOffsetTop = $element.offset().top;
    const elementOffsetBottom = elementOffsetTop + $element.outerHeight();

    const isVisible = $element.is(':visible');
    const isTopEdgeInViewport = viewportEnd > elementOffsetTop && viewportStart < elementOffsetTop;
    const isBottomEdgeInViewport = viewportEnd > elementOffsetBottom && viewportStart < elementOffsetBottom;

    if (edge === EDGES.TOP) {
        return isVisible && isTopEdgeInViewport;
    } else if (edge === EDGES.BOTTOM) {
        return isVisible && isBottomEdgeInViewport;
    }

    return isVisible && isTopEdgeInViewport && isBottomEdgeInViewport;
}

const updateObservables = debounce(() => {
    viewportStart = window.pageYOffset;
    viewportEnd = viewportStart + window.innerHeight;

    elementsToObserve.forEach((element) => {
        element.obsv(isInViewPort(element));
    });
}, 50);

function addScrollListener() {
    if (elementsToObserve.length === 0) {
        window.addEventListener('scroll', updateObservables);
    }
}

function removeScrollListener() {
    if (elementsToObserve.length === 0) {
        window.removeEventListener('scroll', updateObservables);
    }
}

ko.bindingHandlers.inViewport = {
    init(element, valueAccessor) {
        const $element = $(element);
        const obsv = ko.isObservable(valueAccessor()) ? valueAccessor() : valueAccessor().obsv;
        const edge = valueAccessor().edge || EDGES.BOTH;

        const config = { $element, obsv, edge };

        addScrollListener();
        elementsToObserve.push(config);
        obsv(isInViewPort(config));

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            elementsToObserve.splice(elementsToObserve.indexOf(config), 1);
            removeScrollListener();
        });
    },
};
