import { observable } from 'knockout';
import router from 'app/model/router';
import appConfig from 'app/model/config';
import searchEvents from 'search/config/events';
import { mapTrueFalseStringToBoolean } from 'core/utils/stringToBoolean';
import { search as searchService } from 'minimal/module/search/service/search';
import { jobsSortOptions } from '../../../../model/jobsSorting';

const DEFAULT_SORT_BY_VALUE = 'RELEVANCY';

export default class SortingMenuViewModel {

    constructor() {
        this.sortOptions = observable(this._getOptions());

        this.selectedSortOption = observable();

        this._resolveSortOption = this._resolveSortOption.bind(this);

        const _queryParams = router.routeParams().query || {};

        this._routeParamsSub = router.routeParams.subscribe(({ query }) => {
            this.sortOptions(this._getOptions(query));
            this._resolveSortOption(query && query.sortBy);
        }, this);

        this._resolveSortOption(_queryParams.sortBy);

        searchEvents.results.sortByUpdated.add(this._resolveSortOption);
    }

    sort(item) {
        searchEvents.query.params.dispatch({
            sortOption: item,
        });

        searchEvents.query.filterSearch.dispatch(true);
    }

    _resolveSortOption(sortBy = DEFAULT_SORT_BY_VALUE) {
        const selectedSortOption = this.sortOptions().find(({ value }) => sortBy === value)
             || this.sortOptions().find(({ value }) => DEFAULT_SORT_BY_VALUE === value);

        this.selectedSortOption(selectedSortOption);
    }

    _getOptions(query) {
        const isDistanceEnabled = mapTrueFalseStringToBoolean(appConfig.getSettingByKey('DISTANCE_CALCULATION_ENABLED'));

        return jobsSortOptions
            .map(({ name, code, order }) => ({
                text: name,
                value: code === 'RELEVANCY' ? code : `${code}_${order}`,
                code,
                order,
            }))
            .filter(({ code }) => (code === 'DISTANCE' ? isDistanceEnabled && searchService.isLocationSearch(query) : true));
    }

    dispose() {
        searchEvents.results.sortByUpdated.remove(this._resolveSortOption);
        this._routeParamsSub.dispose();
    }

    onPressEnter(viewModel, event) {
        event.preventDefault();
    }

}
