import ko from 'knockout';
import $ from 'jquery';
import cxHeaderEvents from 'cx/component/cx-header/config/events';

/**
 * Wraps given function in the way where frequent multiple invocations of the result function
 * cause execution of given callback no frequent than provided amount of time
 *
 * @param  {function} callback - function to execute
 * @param  {number} frequencyTime - number of miliseconds between allowed callback execution
 * @return {function} wrapper function
 */
function throttle(callback, frequencyTime) {
    let timeout = null;
    const frequency = frequencyTime || 0;

    return () => {
        if (timeout) {
            return;
        }

        timeout = setTimeout(() => {
            callback();
            timeout = null;
        }, frequency);
    };
}

/**
 *
 * When the browser window has been scrolled down and the custom header is out of viewport
 * (it's height is smaller than the distance scrolled), the element is being hidden
 * and the signal is dispatched to notify custom header dependent elements about the change.
 * When the browser is scrolled up to the top, the custom header is shown again.
 *
 * @example
 * <header class="custom-header" data-bind="customHeaderVisibility"></header>
 */
ko.bindingHandlers.customHeaderVisibility = {
    init(element) {
        const $element = $(element);
        let $window = $(window);

        const onScroll = throttle(() => {
            const scrollTop = $window.scrollTop();
            const elementHeight = $element.outerHeight(false);

            let visibleHeight = elementHeight - scrollTop;

            visibleHeight = (visibleHeight > 0) ? visibleHeight : 0;

            cxHeaderEvents.customHeaderVisibilityChanged.dispatch(visibleHeight);
        }, 25);

        $window.on('scroll', onScroll);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            $window.off('scroll', onScroll);
            $window = null;
        });

        setTimeout(onScroll);
    },
};