import ko from 'knockout';
import metadataConfig from 'apply-flow/config/metadata';
import '../component/attachment-upload-button/component';
import '../component/attachment-preuploaded/component';
import '../component/referral-resume-block/component';
import '../extender/elementsLivingTime';
import '../module/misc-attachments/config/module';

import ViewModel from '../FileUploadViewModel';
import template from '../file-upload.html';

ko.components.register('file-upload', { viewModel: ViewModel, template });

metadataConfig.registerBlock('ORA_DOCUMENTS', {
    component: 'file-upload',
});
