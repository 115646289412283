import ko from 'knockout';
import a11yEvents from 'core/a11y/events';
import i18n from 'core/i18n/i18n';

const ARROW_DOWN = 40;
const A11Y_HINT_KEY = 'search.a11y.recent-searches-drop-down-opened';

/**
 * Displays autocompleter's dropdown with matching results
 *
 * @param {string} triggeringInputId - ID of <input /> acting as an autocompleter
 * @param {observable} isMenuOpen - KO observable indicating if menu is currently visible
 *
 * @example
 * <div data-bind="showRecentSearches: { triggeringInputId: 'searchInputId', isMenuOpen: isMenuOpen }"></div>
 */
ko.bindingHandlers.showRecentSearches = {
    init(element, accessor) {
        const visible = accessor().isMenuOpen;
        const triggeringNode = document.getElementById(accessor().triggeringInputId);
        const recentSearchesNode = element.parentElement;

        const hideRecentSearches = () => {
            setTimeout(() => {
                if (!recentSearchesNode.contains(document.activeElement)
                    && document.activeElement !== triggeringNode) {
                    visible(false);
                }
            }, 1);
        };

        const showRecentSearches = () => {
            visible(true);
        };

        const applyFocus = (event) => {
            const recentSearches = document.getElementsByClassName('menu-option');

            if (recentSearches && event.keyCode === ARROW_DOWN) {
                recentSearches[0].focus();
            }
        };

        const visibleSubscription = visible.subscribe((isVisible) => {
            if (isVisible) {
                a11yEvents.status.dispatch(i18n(A11Y_HINT_KEY, {
                    itemscount: accessor().itemsCount(),
                }));

                recentSearchesNode.classList.add('visible');
            } else {
                recentSearchesNode.classList.remove('visible');
            }
        });

        recentSearchesNode.addEventListener('focusout', hideRecentSearches);

        triggeringNode.addEventListener('focus', showRecentSearches);
        triggeringNode.addEventListener('blur', hideRecentSearches);
        triggeringNode.addEventListener('touchstart', showRecentSearches);
        triggeringNode.addEventListener('keydown', applyFocus);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            triggeringNode.removeEventListener('focus', showRecentSearches);
            triggeringNode.removeEventListener('blur', hideRecentSearches);
            triggeringNode.removeEventListener('touchstart', showRecentSearches);
            triggeringNode.removeEventListener('keydown', applyFocus);

            recentSearchesNode.removeEventListener('focusout', hideRecentSearches);

            visibleSubscription.dispose();
        });
    },
};