import 'core/i18n/binding/i18n';
import 'core/i18n/binding/i18nAttr';
import 'core/i18n/binding/i18nLang';
import 'core/i18n/binding/i18nRequired';
import 'core/i18n/component/i18n/component';
import 'core/router/binding/route';
import 'core/router/binding/view';
import 'core/router/binding/routeTransition';
import 'core/router/binding/scrollReset';
import 'core/router/binding/currentRoute';
import './routing';
import 'ce-common/service/webpackTemplateEngine';
import 'ce-common/binding/boolAttr';
import 'ce-common/binding/autocomplete';
import 'cx/model/eventMediator';
import 'ce-common/component/accessibility-messages/config/component';
import 'ce-common/component/unsupported-browser-message/component';
import 'ce-common/module/captcha/config/module';
import 'ce-common/module/downloader/config/module';
import 'ce-common/module/internal-user/config/routing';
import 'ce-common/service/errorHandler';
import 'app/module/ce-common/component/cx-select-single';
import 'cx/module/favourite/config/module';
import 'cx/module/job-details/config/module';
import 'cx/module/job-preview/config/module';
import 'cx/module/apply-flow/config/module';
import 'cx/module/apply-flow-light/component';
import 'cx/module/apply-flow-post/config/module';
import 'cx/module/search/config/module';
import 'cx/module/candidate-verification/config/module';
import 'cx/module/custom-content/config/module';
import 'cx/module/splash-page/config/module';
import 'cx/module/campaign/config/module';
import 'cx/module/candidate-self-service/config/module';
import 'cx/module/talent-community/config/module';
import 'cx/component/cookie-consent/component';
import 'cx/component/cx-header/component';
import 'cx/component/cx-header-links/component';
import 'cx/component/cx-footer/component';
import 'cx/component/custom-header/component';
import 'cx/component/custom-footer/component';
import 'cx/component/home-page/component';
import 'cx/component/internal-user-login-button/component';
import 'cx/component/splash-page/component';
import 'cx/component/spinner/component';
import 'cx/component/view-all-jobs/component';
import 'cx/component/ios-bottom-spacer/component';
import 'cx/component/logo';
import 'cx/component/modal-spinner/component';
import 'cx/component/skip-navigation-link/component';
import 'cx/component/indeterminate-progress-bar/component';
import 'cx/component/language-select/component';
import 'cx/component/cx-notifications/component';
import 'cx/component/cx-dialog/component';
import 'cx/component/user-menu/component';
import 'core/form/config/component';
import 'core/l10n/extender/l10n';
import 'core/component/inline-spinner/component';
import 'ce-common/module/error-404/config/module';
import 'ce-common/binding/a11y/trapFocus';
import 'ce-common/binding/a11y/setFocusOnShiftTab';
import 'ce-common/binding/a11y/setFocusOnTab';
import 'ce-common/binding/a11y/keysHandler';
import 'ce-common/binding/a11y/setInitialFocus';
import 'ce-common/binding/a11y/setFocusOnClick';
import 'ce-common/binding/a11y/skipTo';
import 'ce-common/binding/a11y/toggleFocusIndicator';
import 'ce-common/binding/infiniteScroll';
import 'ce-common/binding/scrollTo';
import 'ce-common/binding/setBodyScrollTo';
import 'ce-common/binding/frontImageBackground';
import 'ce-common/binding/setBodyClass';
import 'cx/binding/a11y/keyboardNavigation';
import 'cx/binding/a11y/disable';
import 'cx/binding/a11y/hideAfterAnimation';
import 'cx/binding/loadingIndicator';
import 'cx/binding/swipe';
import 'cx/binding/keepInView';
import 'cx/binding/cssWhenElementShown';
import 'cx/binding/disableBodyScroll';
import 'cx/binding/isDialogVisible';
import 'cx/binding/refreshPositionDialog';
import 'cx/binding/inViewport';
import 'cx/binding/cssOnScrollDown';
import 'cx/binding/adjustToCustomHeader';
import 'cx/binding/cssScreenType';
import 'cx/binding/isFocusIn';
import 'cx/binding/clickOverlay';
import 'cx/binding/copyToClipboard';
import 'cx/binding/calculateViewportHeight';
import 'cx/module/site-editor/config/module';
import 'offer/config/module';
import 'ce-common/service/old-urls/routing';
import 'ce-common/binding/clickOutside';
import 'ce-common/binding/a11y/setTabIndex';
import 'cx/module/candidate-verification/component/session-expire';
import 'minimal/module/search/component/search-box-popup/component';
import 'minimal/module/apply-flow/module/indeed-import-handler/component';

import { components } from 'knockout';

import ViewModel from '../CxViewModel';
import template from '../cx.html';

components.register('cx', { viewModel: ViewModel, template });
