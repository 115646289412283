import router from 'app/model/router';
import i18n from 'core/i18n/i18n';
import searchEvents from 'search/config/events';


router.configure({
    'home-page': {
        parent: 'cx',
        url: '/:?query:',
        isDefault: true,
        view: {
            'cx-header': 'favourite-navbar-btn',
            'cx-header-mobile': 'favourite-navbar-btn',
            'cx-header-panel': 'favourite-panel',
            main: 'splash-page',
            'search-header': 'search-box-home-page',
            'search-main': 'home-page',
            'search-aside': null,
            'cx-header-opening-panel': null,
        },
    },
    search: {
        parent: 'cx',
        url: '/requisitions:?query:',
        enter() {
            searchEvents.jobDetails.isClosing.dispatch(false);
        },
        title: i18n('search.page-title'),
        view: {
            'cx-header': 'favourite-navbar-btn',
            'cx-header-mobile': 'favourite-navbar-btn',
            'cx-header-panel': 'favourite-panel',
            main: 'search',
            'search-header': 'search-box-results-page',
            'search-main': 'search-results',
            'search-aside': null,
            'cx-header-opening-panel': 'search-header',
        },
    },
    'job-preview': {
        parent: 'search',
        url: '/preview/{jobId}',
        title: i18n('search.page-title'),
        enter() {
            searchEvents.jobDetails.renderedPreview.dispatch(true);
        },
        exit() {
            searchEvents.jobDetails.renderedPreview.dispatch(false);
        },
        view: {
            'search-aside': 'job-details',
        },
        isNotNewScreen: true,
    },
    'job-full-view': {
        parent: 'search',
        url: '/job/{jobId}',
        view: {
            'search-aside': 'job-details',
        },
        isNotNewScreen: true,
    },
});
