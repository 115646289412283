import ko from 'knockout';
import appConfig from 'app/model/config';
import router from 'app/model/router';
import themeEvents from 'cx/module/site-editor/config/events';
import i18n from 'core/i18n/i18n';

export default class HomePageViewModel {

    constructor() {
        const { template: { welcomeText, mainCallForAction } = {} } = appConfig;

        this.welcomeText = ko.observable(welcomeText);
        this.mainCallForAction = mainCallForAction || i18n('search.view-all-jobs');

        themeEvents.welcomeTextUpdated.add(welcomeTextUpdated => this.welcomeText(welcomeTextUpdated));
    }

    goToSearchResults() {
        router.go('search', { jobId: null }, { inherit: false });

        return true;
    }

}
