import { pureComputed } from 'knockout';
import AutosuggestFormElementMoheganViewModel from
    'core/form/component/autosuggest-form-element/AutosuggestFormElementMoheganViewModel';
import debouncePromise from 'core/utils/debouncePromise';

export const USE_THROTTLE_THRESHOLD = 5;

export default class GeoHierarchySearchFormElementMoheganViewModel extends AutosuggestFormElementMoheganViewModel {

    constructor(...args) {
        super(...args);

        this.isDisabled = pureComputed(this._isDisabled, this);
        this.getOptions = this.getOptions.bind(this);
        this.debouncedGetOptions = debouncePromise(this.element.getOptions.bind(this.element), 500);
    }

    async getOptions(context) {
        const value = this.element.value();

        if ('term' in context) {
            return await this.debouncedGetOptions(context.term);
        } else if (value && !this.contains(value)) {
            return await this.element.getOptions(value);
        }

        return this.element.options();
    }

    _isDisabled() {
        return this.element.isDisabled() || this.isEmpty();
    }

}