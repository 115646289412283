import { components } from 'knockout';

import metadataConfig from 'apply-flow/config/metadata';
import metadataRepository from 'apply-flow/module/profile-items/service/Metadata';
import 'apply-flow/module/work-and-education-timeline/component/beautiful-timeline/component';
import 'apply-flow/module/work-and-education-timeline/component/beautiful-timeline-item/component';
import 'apply-flow/module/work-and-education-timeline/component/timeline-form-dialog/component';
import 'apply-flow/module/work-and-education-timeline/component/timeline-form-dialog/component/timeline-form-builder/component';

import ViewModel from '../WorkAndEducationTimelineViewModel';
import template from '../work-and-education-timeline.html';

components.register('work-and-education-timeline', { viewModel: ViewModel, template });

metadataConfig.registerBlock('ORA_TIMELINE', {
    component: 'work-and-education-timeline',
    repository: metadataRepository,
    requireBlockProperties: true,
});
