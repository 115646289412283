import SearchHandler from './SearchHandler';

const HANDLED_PARAMS = [
    'keyword',
    'location',
    'locationId',
    'radius',
    'radiusUnit',
    'latitude',
    'longitude',
    'countryCode',
    'zipcode',
    'zipcodeKeyword',
    'mode',
];

export default class BasicSearchHandler extends SearchHandler {

    /**
     * @override
     */
    _getHandledParameters() {
        return HANDLED_PARAMS;
    }

    /**
     * @override
     */
    initialize(params) {
        super.initialize(params);
    }

}