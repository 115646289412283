import ko from 'knockout';
import 'ojs/ojdialog';

/**
 * Controls ojDialog by responding to state of given observable.
 *
 * @param {observable} value - KO observable indicating if dialog should be open or not
 *
 * @example
 * <oj-dialog data-bind="isDialogVisible: isVisible"></oj-dialog>
 */
ko.bindingHandlers.isDialogVisible = {
    init(element, accessor) {
        const togglesOn = accessor();

        const subscription = togglesOn.subscribe((shouldOpen) => {
            if (shouldOpen) {
                element.open();
            } else {
                element.close();
            }
        });

        function onClose() {
            togglesOn(false);
        }

        function onOpen() {
            togglesOn(true);
        }

        setTimeout(() => {
            element.addEventListener('ojClose', onClose);
            element.addEventListener('ojOpen', onOpen);
        }, 0);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            element.removeEventListener('ojClose', onClose);
            element.removeEventListener('ojOpen', onOpen);
            subscription.dispose();
        });
    },
};