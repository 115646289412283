import ko from 'knockout';
import $ from 'jquery';

/**
 * Triggers refreshing ojDialog position based on observable value
 *
 * @param {observable} value - KO observable indicating if dialog should position refresh
 *
 * @example
 * <div data-bind="ojComponent: { component: 'ojDialog' }, refreshPositionDialog: refresh"></div>
 */
ko.bindingHandlers.refreshPositionDialog = {
    init(element, accessor) {
        const { refresh } = accessor();

        function shouldPositionRefresh(shouldRefresh) {
            if (shouldRefresh) {
                setTimeout(() => {
                    $(element).ojDialog('option', 'position.my.vertical', 'center');
                    $(element).ojDialog('option', 'position.my.horizontal', 'center');
                }, 0);
            }
        }

        shouldPositionRefresh(refresh());

        const subscription = refresh.subscribe(shouldPositionRefresh);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            subscription.dispose();
        });
    },
};

