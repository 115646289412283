import ko from 'knockout';

/**
 * Used mainly for closing a modal when user clicks outside of it - the overlay layer.
 * Any click on an element inside the overlay is not disturbed.
 *
 * @param {Function} View model's click handler to be invoked once bound element is clicked.
 *
 * @example
 *  <div class="cx-overlay" data-bind="clickOverlay: handler">
 *      <div class="[modal class]">
 *          [modal content]
 *      </div>
 *  </div>
 */
ko.bindingHandlers.clickOverlay = {
    init(element, valueAccessor, allBindings, viewModel) {
        const clickAction = valueAccessor().bind(viewModel);

        function onClick(event) {
            if (event.target === element) {
                clickAction();
                event.stopImmediatePropagation();
            }
        }

        element.addEventListener('click', onClick, true);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            element.removeEventListener('click', onClick, true);
        });
    },
};