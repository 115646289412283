import 'job-details/binding/scrollable';
import 'job-details/component/scrollbar/scrollbar';
import 'job-details/component/copy-link/component';
import 'job-details/module/media-player/config/module';
import 'job-details/module/media-tiles/config/module';
import 'job-details/module/job-details-summary/config/module';
import './routing';

import { components } from 'knockout';

import ViewModel from '../FakeJobDetailsViewModel';
import template from '../../../../job-details/job-details.html';

components.register('fake-job-details', { viewModel: ViewModel, template });
