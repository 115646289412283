import ko from 'knockout';
import $ from 'jquery';


/**
 * Sets the value passed in params to true if after click focus is still inside the element
 * this binding is used on or this element's children. False otherwise.
 *
 * @params {ko.observable} - indicates if focus is still within element after user clicks.
 *
 * @listens click
 *
 * @example
 * class VM {
 *   this.isFocusIn = ko.observable();
 * }
 *
 * <div class="location-bar" data-bind="isFocusIn: isFocusIn">
 */
ko.bindingHandlers.isFocusIn = {
    init(element, valueAccessor) {
        const $element = $(element);
        const isFocusIn = valueAccessor();
        const $document = $(document);

        function setIsFocusIn(event) {
            const focusInElement = $(event.target).closest($element).length;

            isFocusIn(Boolean(focusInElement));
        }

        $document.click(setIsFocusIn);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            $document.unbind('click', setIsFocusIn);
        });
    },
};
