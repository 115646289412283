import '../module/facet/config/module';
import '../module/filter-panel/config/module';
import '../component/search-result-item/component';
import '../component/search-filters/component';
import '../component/sorting-menu/component';
import '../component/filter-bar/component';
import '../component/no-results/component';
import '../component/search-header-overlay/component';
import '../component/talent-community-tile/component';
import '../component/search-jobs-to-candidate/component';
import '../SearchResultsAbstractViewModel';

import { components } from 'knockout';

import ViewModel from '../SearchResultsViewModel';
import template from '../search-results.html';

components.register('search-results', { viewModel: ViewModel, template });
