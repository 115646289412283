import ko from 'knockout';

export default function SearchFiltersViewModel(params) {
    this.facets = params.facets;

    this.areFacetsVisible = ko.observable(false);

    this.toggleFacets = () => {
        this.areFacetsVisible(!this.areFacetsVisible());
    };
}
