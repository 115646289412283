import router from 'app/model/router';
import cxEvents from 'cx/config/events';

router.configure({
    'cc-page': {
        parent: 'cx',
        url: '/pages/{pageCode}/:?query:',
        view: {
            layout: 'custom-content-layout',
        },
        enter() {
            cxEvents.loaded.dispatch();
        },
    },
});
