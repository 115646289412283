import { components } from 'knockout';

import ViewModel from './EmailVerificationViewModel';
import template from './email-verification.html';
import footerTemplate from './email-verification-footer.html';

import './component/email-verification-form/component';

components.register('email-verification-footer', {
    template: footerTemplate,
});

components.register('email-verification', { viewModel: ViewModel, template });
