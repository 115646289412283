import JobDetailsMoheganViewModel from 'job-details/JobDetailsMoheganViewModel';
import fakeJobDetailsConfig from './config/fakeJobDetailsConfig.json';
import { getWorkLocations } from './config/workLocations';

export default class FakeJobDetailsMoheganViewModel extends JobDetailsMoheganViewModel {

    loadJob() {
        fakeJobDetailsConfig.workLocations = getWorkLocations();
        this.job(fakeJobDetailsConfig);
        this.loaded(true);

        return Promise.resolve(fakeJobDetailsConfig);
    }

}