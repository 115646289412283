import metadataConfig from 'apply-flow/config/metadata';

import { components } from 'knockout';

import ViewModel from '../CandidateLinksViewModel';
import template from '../candidate-links.html';

components.register('candidate-links', { viewModel: ViewModel, template });

metadataConfig.registerBlock('ORA_LINKS', {
    component: 'candidate-links',
});
