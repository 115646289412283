import ko from 'knockout';
import $ from 'jquery';
import keycodes from 'core/config/keycodes';


/**
 * Binding used to toggle link sub-menus.
 *
 * @param {boolean} isVertical - true(vertical)/false(horizontal)
 * @param {string} type - type of action to perform - 'open'/'close'
 * @param {function} handler - handler to fire on keys responsible for opening/closing
 *
 * @example
 *      <a data-bind="toggleSubmenu: {type: 'open', isVertical: someObservable, handler: myOpenSubMenuHandler}"></a>
 */

ko.bindingHandlers.toggleSubmenu = {
    init(container, accessor) {
        const { type, handler } = accessor();
        const $container = $(container);

        function getKeys(isVertical) {
            if (type === 'open') {
                return isVertical ? [keycodes.DOWN_ARROW] : [keycodes.RIGHT_ARROW];
            }

            return isVertical ? [keycodes.UP_ARROW, keycodes.ESC] : [keycodes.LEFT_ARROW, keycodes.ESC];
        }

        function toggle(event) {
            const keys = getKeys(accessor().isVertical);

            if (keys.indexOf(event.which) > -1) {
                event.preventDefault();

                handler();
            }
        }

        $container.on('keydown', toggle);

        ko.utils.domNodeDisposal.addDisposeCallback(container, () => {
            $container.off('keydown', toggle);
        });
    },
};