import ko from 'knockout';
import router from 'app/model/router';
import favouriteService from 'favourite/service/favourite';
import favouriteEvents from 'favourite/config/events';
import userTracking from 'cx/service/userTracking';
import { setFocusWithDelay } from 'core/a11y/service/setFocusWithDelay';

const FAVOURITE_JOB_SELECTOR = '.joblist-tile';
const NO_FAVOURITE_JOBS_SELECTOR = '.favourites-no-results';

export default class FavouritePanelViewModel {

    static get LIST_LIMIT() {
        return 3;
    }

    constructor() {
        const _favourites = favouriteService.query();

        this.shouldOpenPanelOnReturn = false;
        this.isPanelVisible = ko.observable(false);
        this.isListShowAll = ko.observable(false);
        this.isCookieAllowed = ko.observable(false);
        this.favourites = ko.pureComputed(() => _favourites.slice(0, this.listLimit()));
        this.favouritesTotalLength = favouriteService.length;

        this.onUnpinJob = this._onUnpinJob.bind(this);

        this.listLimit = ko.pureComputed(() => {
            if (this.isListShowAll()) {
                return this.favouritesTotalLength();
            }

            return FavouritePanelViewModel.LIST_LIMIT;
        });

        this.hasMore = ko.pureComputed(() =>
            this.favouritesTotalLength() > FavouritePanelViewModel.LIST_LIMIT);

        this.openJobDetails = (job) => {
            this.shouldOpenPanelOnReturn = true;

            router.go('job-details', {
                jobId: job.id,
            });
        };

        favouriteEvents.openPanel.add(() => {
            this.isPanelVisible(true);
            this.isCookieAllowed(userTracking.isCookieAllowed());
        });

        favouriteEvents.jobDetailsClosed.add((isClosing) => {
            if (this.shouldOpenPanelOnReturn && isClosing) {
                favouriteEvents.openPanel.dispatch();
                this.shouldOpenPanelOnReturn = false;
            }
        });

        favouriteEvents.closePanel.add(() => {
            this.isPanelVisible(false);
            this.isListShowAll(false);
        });

        router.route.subscribe(this.closePanel);
    }

    closePanel() {
        favouriteEvents.closePanel.dispatch();
    }

    toggleListShowAll() {
        this.isListShowAll(!this.isListShowAll());
    }

    _onUnpinJob() {
        const selector = this.favouritesTotalLength() > 0
            ? FAVOURITE_JOB_SELECTOR
            : NO_FAVOURITE_JOBS_SELECTOR;

        setFocusWithDelay(selector);
    }

}