import { components } from 'knockout';

import '../component/text/component';
import '../component/location/component';
import '../component/static-url/component';

import ViewModel from '../JobMetaItemViewModel';
import template from '../job-meta-item.html';

components.register('job-meta-item', { viewModel: ViewModel, template });
