import ko from 'knockout';
import searchEvents from 'search/config/events';
import RecentSearches from 'search/service/RecentSearches';

export default function RecentSearchesViewModel(params) {
    const recentSearches = new RecentSearches();

    this.keyword = params ? params.keyword : '';
    this.isMenuOpen = ko.observable(false);
    this.showAll = ko.observable(false);

    const DEFAULT_LIMIT = 5;

    const _recentSearches = ko.pureComputed(() => {
        const items = recentSearches.getAll().slice();

        return items.reverse();
    });

    this.isVisible = ko.pureComputed({
        read() {
            return !this.keyword().length && this.isMenuOpen();
        },
        write(isVisible) {
            this.isMenuOpen(isVisible);
        },
        owner: this,
    });

    this.toggleMenu = () => {
        this.isMenuOpen(!this.isMenuOpen());
        this.showAll(false);
    };

    this.hideMenu = () => {
        this.isMenuOpen(false);

        return true;
    };

    this.toggleSearches = () => {
        this.showAll(!this.showAll());
    };

    this.limit = ko.pureComputed(() => {
        if (this.showAll()) {
            return _recentSearches().length;
        }

        return DEFAULT_LIMIT;
    });

    this.recentSearchItem = ko.pureComputed(() => _recentSearches().slice(0, this.limit()));

    this.visibleRecentSearchItemsCount = ko.pureComputed(() => Math.min(_recentSearches().length, this.limit()));

    this.isOverDefaultLimit = ko.pureComputed(() => _recentSearches().length > DEFAULT_LIMIT);

    this.runSearch = searchEvents.query.search.dispatch;

    this.runSearchAndClose = (recentSearch, shouldRedirect) => {
        this.runSearch(recentSearch, shouldRedirect);
        this.isMenuOpen(false);
    };
}