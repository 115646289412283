import { components } from 'knockout';
import { register as registerTemplate } from 'ce-common/service/templateRepository';
import lastNameVerification from 'candidate-verification/templates/candidate-verification-last-name-check.html';
import dateOfBirthVerification from 'candidate-verification/templates/candidate-verification-date-of-birth-check.html';

import ViewModel from './CsConfirmDialogViewModel';
import template from './cs-confirm-dialog.html';

registerTemplate('candidate-verification-last-name-chek', lastNameVerification);
registerTemplate('candidate-verification-date-of-birth-chek', dateOfBirthVerification);
components.register('cs-confirm-dialog', { viewModel: ViewModel, template });
