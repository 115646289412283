import ko from 'knockout';
import $ from 'jquery';


/**
 * Add CSS class to element when element specified by data-binding-id='some-id' is in viewport.
 *
 * @param {object} - key - CSS class to be added
 *                   value - id used in data-binding-id attribute
 *
 * @example
 *
 * <div data-bind="cssWhenElementShown: { 'some-class': 'some-id' }"></div>
 *
 * <div data-binding-id="some-id"></div>
 */

ko.bindingHandlers.cssWhenElementShown = {
    init(element, valueAccessor) {
        const className = Object.keys(valueAccessor()).shift();

        const $relatedElement = $(`[data-binding-id="${valueAccessor()[className]}"]`);
        const $window = $(window);

        if (!$relatedElement.length) {
            return;
        }

        $window.on('scroll.cx.cssWhenElementShown', () => {
            const shouldHaveClass = ($window.scrollTop() + $window.height() > $relatedElement.offset().top);

            ko.utils.toggleDomNodeCssClass(element, className, shouldHaveClass);
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            $window.off('scroll.cx.cssWhenElementShown');
        });
    },
};