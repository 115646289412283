import ko from 'knockout';

export default class MediaPlayerViewModel {

    constructor(params) {
        this.media = params.media;
        this.type = ko.pureComputed(() => this.media().type().toLowerCase());
    }

}
