import searchEvents from 'search/config/events';
import AbstractModeMenuViewModel from './AbstractModeMenuViewModel';

class ModeMenuViewModel extends AbstractModeMenuViewModel {

    handleModeSet(mode) {
        super.handleModeSet(mode);
        searchEvents.query.params.dispatch({ mode });
    }

}

export default ModeMenuViewModel;
