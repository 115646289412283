import { pureComputed, observable, observableArray } from 'knockout';
import cxEvents from 'cx/config/events';
import tokenService from '../candidate-verification/service/token';
import router from 'app/model/router';
import lightCandidateService from './service/lightCandidate';
import candidateSelfServiceEvents from './config/events';
import jobAlertService from '../job-alerts/service/jobAlerts';
import CandidateChallengeAbstractViewModel from '../candidate-verification/component/challenge-layout/CandidateChallengeAbstractViewModel';
import challengeService from 'candidate-verification/service/challenge';
import { ATTEMPS_LIMIT_REACHED, PIN_LIMIT_REACHED } from 'candidate-verification/config/pinVerificationStatus';
import applicationsModel from './model/applications';
import draftApplicationsModel from './model/draftApplications';
import applicationService from 'apply-flow/service/application';
import siteListService from './service/siteList';
import { session } from '../candidate-verification/service/user-session/session';
import sessionPersistence from 'candidate-verification/service/user-session/sessionPersistence';
import { setApplicationsSiteNames } from 'cx/module/candidate-self-service/service/applicationsSiteNames';
import { loadDrafts } from 'cx/module/candidate-self-service/service/loadDrafts';
import scrollKeeper from 'minimal/service/scrollKeeper';
import { isTCOptInEnabled } from 'app/service/isTCOptInEnabled';

export default class CandidateSelfServiceViewModel extends CandidateChallengeAbstractViewModel {

    constructor() {
        super();

        this.candidateFullName = observable();
        this.candidateEmail = observable();
        this.candidateNumber = observable();
        this.isCandidateCwk = observable();
        this.jobAlerts = observableArray([]);
        this.applicationsLoaded = observable(false);
        this.lastRequisitionId = observable(0);
        this.showJobsToCandidate = pureComputed(() => this.lastRequisitionId() > 0);
        this.isJobsToCandidateComponentReady = observable(false);
        this.showSkeletonForJobsToCandidate = pureComputed(this._shouldShowSkeletonForJobsToCandidate, this);

        this.enableOptInCSS = observable(false);
        this.isPinRequired = observable();
        this.isOptionsShown = observable(false);
        this.isEditProfileOpen = observable(false);
        this.isKeepMeSignedInEnabled = observable(sessionPersistence.isEnabled());
        this.isTCSectionReady = observable(false);
        this.showSkeletonForTCSection = pureComputed(this._shouldShowSkeletonForTCSection.bind(this));

        this.isClickOutsideActive = pureComputed(() => this.isOptionsShown() && !this.isEditProfileOpen());
        this.showDeleteProfileLink = pureComputed(() => !this.isCandidateCwk());

        this.isTCOptInEnabled = isTCOptInEnabled();

        this.reloadUser();

        this._initialize = this._initialize.bind(this);

        candidateSelfServiceEvents.reloadUser.add(this.reloadUser);
    }

    onPinValid() {
        this.isPinRequired(false);
        this._initialize();
    }

    async _initialize() {
        if (challengeService.isChallengeRequired()) {
            await this._triggerChallenge()
                .then(this._onTriggeredChallenge.bind(this))
                .catch((error) => {
                    this._handleError(error);

                    this.isPinRequired(challengeService.isChallengeRequired());
                });

            if (this.isPinRequired()) {
                cxEvents.loaded.dispatch();

                return;
            }
        }

        this.isEditProfileOpen(false);
        this.isOptionsShown(false);
        this._initializeWithToken();
    }

    _initializeWithToken() {
        this._verifyToken()
            .then(this._onTokenVerified)
            .then(this._loadCandidateData.bind(this))
            .then(this._getPreferencesOptIn.bind(this))
            .catch(this._handleError.bind(this))
            .then(cxEvents.loaded.dispatch);
    }

    async _loadCandidateData(token) {
        draftApplicationsModel.clear();
        applicationsModel.clear();

        const siteList = await siteListService.getSiteList();

        return Promise.all([
            this._loadApplications(siteList),
            loadDrafts(siteList),
            this._loadCandidate(token),
        ]);
    }

    _onTokenVerified(token) {
        cxEvents.loaded.dispatch();

        return token;
    }

    _loadApplications(siteList) {
        return applicationService.getAll()
            .then(applications => setApplicationsSiteNames(applications, siteList))
            .then(this._setApplications.bind(this));
    }

    _shouldShowSkeletonForJobsToCandidate() {
        if (!this.applicationsLoaded()) {
            return true;
        }

        if (this.applicationsLoaded() && !this.showJobsToCandidate()) {
            return false;
        }

        return !this.isJobsToCandidateComponentReady();
    }

    _shouldShowSkeletonForTCSection() {
        const isTCSectionRendered = this.enableOptInCSS() || this.isTCOptInEnabled;

        return !this.isTCSectionReady() && !isTCSectionRendered;
    }

    reloadUser = () => {
        this._resetScroll();
        this._initialize();
    }

    toggleMenuOptions() {
        this.isOptionsShown(!this.isOptionsShown());
    }

    signOut() {
        session.signOut()
            .then(() => router.go('home-page'));
    }

    onEditProfile() {
        this.isEditProfileOpen(true);
    }

    closeMenu() {
        this.isOptionsShown(false);
    }

    signOutAll() {
        session.signOutAll()
            .then(() => router.go('home-page'));
    }

    async _setApplications(applications) {
        if (applications.length) {
            const appliedJobs = await applicationService.getAppliedJobs(applications);

            const lastPostedRequisitionId = applicationService.getLastPostedApplicationReqId(
                applications,
                appliedJobs,
            );

            applicationService.setRequisitionDetails(applications, appliedJobs);

            this.lastRequisitionId(lastPostedRequisitionId);
        }

        applicationsModel.setLoadedData(applications);

        this.applicationsLoaded(true);
    }

    _loadCandidate(token) {
        return lightCandidateService.get(token.candidateNumber)
            .then(({ displayName, email, candidateNumber, cwkCandidateFlag }) => {
                this.candidateFullName(displayName);
                this.candidateEmail(email);
                this.candidateNumber(candidateNumber);
                this.isCandidateCwk(cwkCandidateFlag);
            });
    }

    _getPreferencesOptIn() {
        return jobAlertService.getPreferences(this.candidateNumber())
            .then((response) => {
                this.enableOptInCSS(response.optIn !== undefined);
            })
            .finally(() => {
                this.isTCSectionReady(true);
            });
    }

    _handleError(error) {
        if (error === 'token-invalid' || error === 'token-expired') {
            router.go('home-page');

            return;
        }

        if (error === ATTEMPS_LIMIT_REACHED || error === PIN_LIMIT_REACHED) {
            this.verificationStatus(ATTEMPS_LIMIT_REACHED);
            this.isPinRequired(true);

            return;
        }

        super._handleError(error);
    }

    _verifyToken() {
        if (tokenService.accessCodeExists()) {
            return Promise.resolve(tokenService.get());
        }

        return tokenService.verifyToken(router.routeParams().token);
    }

    _onTriggeredChallenge(challengeRequired) {
        this.handleMergedCandiate();
        this.isPinRequired(challengeRequired);
    }

    _resetScroll() {
        scrollKeeper.scrollTo(0);
    }

}
