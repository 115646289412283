import router from 'app/model/router';

router.configure({
    'fake-job-details': {
        parent: 'search',
        url: '/fake-job/{jobId}',
        view: {
            'search-aside': 'fake-job-details',
        },
        isNotNewScreen: true,
    },
});