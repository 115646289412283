import './routing';
import '../component/offer-message/component';
import '../component/offer-content/config/module';
import '../component/e-signature/config/component';
import '../component/offer-refusal/config/component';

import { components } from 'knockout';

import ViewModel from '../OfferViewModel';
import template from '../offer.html';

components.register('offer', { viewModel: ViewModel, template });
