import ko from 'knockout';
import router from 'app/model/router';
import searchEvents from 'search/config/events';
import { insertTileToResults } from 'search/mapper/talentCommunityTile';
import stringSanitizer from 'ce-common/service/stringSanitizer';

const TALENT_COMMUNITY_TILE_DEFAULT_POSITION = 5;

export default class SearchResultsAbstractViewModel {

    constructor() {
        this.keywordText = ko.observable();
        this.locationText = ko.observable();
        this.locationLevel = ko.observable();

        this.jobs = ko.observableArray();
        this.facets = ko.observableArray();
        this.totalResults = ko.observable(0);

        this.isLoading = ko.observable(true);
        this.facets = ko.observableArray();
        this.shouldAppendJobs = ko.observable(false);

        const { routeParams } = router;

        /* -- infinite scrolling --*/
        this.page = ko.observable(0);
        this.hasMore = ko.observable(false);

        this.isInfiniteScrollOn = ko.pureComputed(() =>
            this.hasMore() && this.page() < SearchResultsAbstractViewModel.INFINITE_SCROLL_LIMIT);

        this.isInitialized = ko.pureComputed(() =>
            (this.page() === 0 && this.isLoading() === false) || this.page() > 0);

        this.selectedJobId = ko.pureComputed(() => routeParams().jobId);

        this.appendJobs = () => {
            this.shouldAppendJobs(true);
            searchEvents.query.offsetSearch.dispatch(this.page());
        };

        this.scrollHandler = () => {
            this.page(this.page() + 1);

            return this.appendJobs();
        };

        this.updateJobs = (response) => {
            if (!this.shouldAppendJobs()) {
                const jobs = insertTileToResults(response.jobs, TALENT_COMMUNITY_TILE_DEFAULT_POSITION);

                this.jobs(jobs);
            } else {
                const jobs = this.jobs();

                this.jobs(jobs.concat(response.jobs));
            }

            this.totalResults(response.totalCount);
            this.hasMore(response.hasMore);
            this.isLoading(false);
            this.shouldAppendJobs(false);

            const query = routeParams().query || {};

            this.keywordText(stringSanitizer.sanitizeHTML(query.keyword));
            this.locationText(query.location);
            this.locationLevel(query.locationLevel);
        };

        this.searchPerformanceHandler = () => {
            this.isLoading(true);

            if (!this.shouldAppendJobs()) {
                this.jobs([]);
                this.page(0);
            }
        };

        this.hideLoader = () => this.isLoading(false);

        this._initialize = () => {
            const { page } = routeParams();

            if (routeParams().query !== undefined) {
                this.jobs([]);
                this.page(0);
            }

            if (page) {
                for (let i = 0; i < page; i++) {
                    this.scrollHandler();
                }
            }

            searchEvents.results.jobsUpdated.add(this.updateJobs);
            searchEvents.results.facetsUpdated.add(this.facets);
            searchEvents.results.searchFailed.add(this.hideLoader);
            searchEvents.results.searchFired.add(this.searchPerformanceHandler);

            searchEvents.pageLoaded.dispatch();
        };

        this._initialize();
    }

    dispose() {
        searchEvents.results.jobsUpdated.remove(this.updateJobs);
        searchEvents.results.facetsUpdated.remove(this.facets);
        searchEvents.results.searchFailed.remove(this.hideLoader);
        searchEvents.results.searchFired.remove(this.searchPerformanceHandler);
    }

    static get INFINITE_SCROLL_LIMIT() {
        return 2;
    }

}
