import ko from 'knockout';

/**
 * Calculates the necessary shift of elements based on current index and total number of elements
 *
 * @private
 * @param {Number} index - index of current element
 * @param {Number} limit - total number of elements in container
 * @returns {Number} value of shift
 */
function _calculateShift(index, limit) {
    const isLast = (index + 1 === limit);

    if (isLast) {
        return (index * 100) - 66.3333;
    }

    return Math.max((index * 100) - 33.3333, 0);
}

/**
 * Shifts child elements of container based on index of current element
 *
 * @param {ko.observable} currentIndex - index of current element
 *
 * @example
 * When value of 'currentIndex' is changed, element's children are shifted by the calculated value
 *
 * class ViewModel () {
 *     this.currentIndex = ko.observable();
 * }
 *
 * <ul data-bind="mediaSlider: currentIndex">
 *     <!-- shiftable elements -->
 * </ul>
 */
ko.bindingHandlers.mediaSlider = {
    update(element, valueAccessor) {
        const children = [].slice.call(element.children);
        const currentIndex = ko.unwrap(valueAccessor());
        const translateX = _calculateShift(currentIndex, children.length);

        children.forEach((child) => {
            const { style } = child;

            style.transform = `translateX(-${translateX}%)`;
        });
    },
};
