import { components } from 'knockout';

import metadataConfig from 'apply-flow/config/metadata';
import 'apply-flow/module/e-signature/binding/setFocusWhenInvalid';
import 'apply-flow/module/e-signature/component/text-truncate/component';

import ViewModel from '../ESignatureViewModel';
import template from '../e-signature.html';

components.register('apply-flow-e-signature', { viewModel: ViewModel, template });


metadataConfig.registerBlock('ORA_ESIGNATURE', {
    component: 'apply-flow-e-signature',
});
