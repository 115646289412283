import ko from 'knockout';
import $ from 'jquery';
import 'jqueryui-amd/core';
import 'jqueryui-amd/widget';
import 'jqueryui-amd/widgets/mouse';
import screen from 'cx/model/screenInfo';


const dependencies = ko.observableArray([]);
const scrollWidth = $.position.scrollbarWidth();
let scrollPosition = 0;

function shouldDisableScroll() {
    return dependencies().some(dependency => dependency());
}

function setBodyOverflow(shouldDisable) {
    const element = document.querySelector('body');

    if (shouldDisable) {
        scrollPosition = window.pageYOffset;
        element.classList.add('scroll-locked');
        element.style.marginTop = `-${scrollPosition}px`;
    } else {
        element.classList.remove('scroll-locked');
        element.style.marginTop = '';
        window.scrollTo(0, scrollPosition);
    }

    element.style.paddingRight = shouldDisable ? `${scrollWidth}px` : '';
}

/**
 * Disables the ability to scroll the page when passed value is equal to true
 *
 * @param {observable} disabled Flag which indicates whether page can be scrolled or not
 *
 * @example
 * class VM {
 *  this.isPopupOpen = ko.observable(false);
 *
 *  this.openPopup = function () {
 *      this.isPopupOpen(true);
 *  }.bind(this);
 * }
 *
 * <div data-bind="disableBodyScroll: { disabled: isPopupOpen }"></div>
 */
ko.bindingHandlers.disableBodyScroll = {
    init(element, accessor) {
        const dependency = accessor().disabled;

        if (screen.isSmall() || accessor().allScreens) {
            dependencies.push(dependency);
        }

        const subscription = dependency.subscribe(() => {
            setBodyOverflow(shouldDisableScroll());
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            dependencies.remove(dependency);
            subscription.dispose();
        });
    },
};