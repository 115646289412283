import ko from 'knockout';
import router from 'app/model/router';
import suggestionsService from 'search/module/search-box/service/suggestions';
import searchEvents from 'search/config/events';

const MAX_SUGGESTIONS = 5;

export default class SearchBoxAbstractViewModel {

    constructor(params) {
        this.shouldGrabFocus = Boolean(params.shouldGrabFocus);
        this.autocompletePosition = params.autocompletePosition;

        this.location = ko.observable({
            label: '',
            level: '',
        });

        this.keyword = ko.observable({
            label: '',
        });

        this.keywordLabel = ko.pureComputed({
            read() {
                return this.keyword().label;
            },

            write(newLabel) {
                return this.keyword({
                    label: newLabel,
                });
            },

            owner: this,
        });

        this.keywordLabel.subscribe((keyword) => {
            searchEvents.query.params.dispatch({
                keyword,
            });
        });

        function limitedSuggester(suggester, limit) {
            return term => suggester(term).then(suggestions => suggestions.slice(0, limit));
        }

        this.suggester = {
            keyword: limitedSuggester(suggestionsService.getKeywordSuggestions, MAX_SUGGESTIONS),
            location: limitedSuggester(suggestionsService.getLocationSuggestions),
        };

        this.routeParamsListener = router.routeParams.subscribe(this.updateParams.bind(this));

        this.updateParams(router.routeParams());
    }

    updateParams(newParams) {
        const query = ko.utils.extend({
            keyword: '',
            locationId: '',
            location: '',
            locationLevel: '',
        }, newParams.query);

        this.keyword({
            label: query.keyword,
        });
    }

    performSearch() {
        searchEvents.query.basicSearch.dispatch(true);
    }

    dispose() {
        this.routeParamsListener.dispose();
    }

}