import Signal from 'signals';
import searchEvents from 'search/config/events';
import favouriteEvents from 'favourite/config/events';
import searchBoxEvents from 'search/module/search-box/component/search-box-results-page/config/events';

const events = {
    toggleFilters: searchEvents.filterPanel.toggle,
    toggleSearchBox: searchBoxEvents.toggleSearchBox,
    toggleFavouritePanel: new Signal(),
    filtersActive: new Signal(),
};

favouriteEvents.closePanel.add(events.toggleFavouritePanel.dispatch.bind(null, false));
favouriteEvents.openPanel.add(events.toggleFavouritePanel.dispatch.bind(null, true));

events.toggleFavouritePanel.add((isOpen) => {
    events.toggleFavouritePanel.active = false;

    if (isOpen) {
        favouriteEvents.openPanel.dispatch();
    } else {
        favouriteEvents.closePanel.dispatch();
    }

    events.toggleFavouritePanel.active = true;
});

export default events;