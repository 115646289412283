import ko from 'knockout';
import searchHeaderEvents from 'search/module/search-header/config/events';

export default class SearchHeaderButtonsViewModel {

    constructor() {
        this.filtersActive = ko.observable(false);
        this.filtersVisible = ko.observable(false);
        this.searchVisible = ko.observable(false);

        this.buttonsHidden = ko.pureComputed(() => {
            const filtersVisible = this.filtersVisible();
            const searchVisible = this.searchVisible();

            return filtersVisible || searchVisible;
        });

        this.filtersActiveSignal = searchHeaderEvents.filtersActive.add(this.filtersActive);
        this.filtersVisibleSignal = searchHeaderEvents.toggleFilters.add(this.filtersVisible);
        this.searchVisibleSignal = searchHeaderEvents.toggleSearchBox.add(this.searchVisible);
    }

    openFilters() {
        searchHeaderEvents.toggleFilters.dispatch(true);
    }

    openSearchBox() {
        searchHeaderEvents.toggleSearchBox.dispatch(true);
    }

    dispose() {
        this.filtersActiveSignal.detach();
        this.filtersVisibleSignal.detach();
        this.searchVisibleSignal.detach();
    }

}