import ko from 'knockout';
import router from 'app/model/router';


/**
 * Scrolls the page to top each time the router's route changed
 *
 * @example
 * <div class="app-container" data-bind="scrollReset">
 */
ko.bindingHandlers.scrollReset = {
    init(element) {
        const subscription = router.route.subscribe((newRoute) => {
            if (newRoute.isNewScreen) {
                const scrollingElement = document.scrollingElement || document.documentElement;

                scrollingElement.scrollTop = 0;
            }
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            subscription.dispose();
        });
    },
};