import ko from 'knockout';
import appConfig from 'app/model/config';
import { mapTrueFalseStringToBoolean } from 'core/utils/stringToBoolean';
import { search as searchService } from 'minimal/module/search/service/search';
import router from 'app/model/router';
import { isDistanceOutOfRadius } from 'cx/module/search/service/distanceCalculation';
import i18n from 'core/i18n/i18n';

export default class SearchResultItemViewModel {

    constructor(params) {
        this.job = params.job;
        this.hideImage = params.hideImage;
        this.overlayVisible = ko.observable(false);
        this.showOverlay = this.overlayVisible.bind(null, true);
        this.hideOverlay = this.overlayVisible.bind(null, false);
        this.showSecondaryLocations = this._hasSecondaryLocation();

        this.showDistance = this._shouldShowDistance(params.showDistance);

        if (this.showDistance) {
            this.inputRadius = router.routeParams().query?.radius;
            this.isDistanceOutOfRadius = isDistanceOutOfRadius(this.inputRadius, this.job.distance);
            this.distance = this._getDistanceText();
            this.distanceUnit = this.job.radiusUnit.toLowerCase();

            this.distanceStringKey = this._hasSecondaryLocation()
                ? 'search.job-item.distance-multiple-locations'
                : 'search.job-item.distance-single-location';

            this.helpText = this._getHelpText();
            this.helpTextId = `helpText-distanceOutOfRadius-${this.job.id}`;
            this.isTooltipVisible = ko.observable(false);
        }
    }

    showHelpTooltip() {
        this.isTooltipVisible(true);
    }


    hideHelpTooltip() {
        this.isTooltipVisible(false);
    }

    showHelpTooltipOnClick(data, event) {
        this.isTooltipVisible(true);
        event.stopPropagation();
    }

    _getDistanceText() {
        return this.isDistanceOutOfRadius
            ? `+${this._parseDistance(this.inputRadius)}`
            : this._parseDistance(this.job.distance);
    }

    _parseDistance(distance) {
        return Number.parseFloat(distance || 0).toFixed(1).toLocaleString();
    }

    _shouldShowDistance(showDistanceParam) {
        const isDistanceCalculationEnabled = mapTrueFalseStringToBoolean(appConfig.getSettingByKey('DISTANCE_CALCULATION_ENABLED'));

        return showDistanceParam && isDistanceCalculationEnabled && searchService.isLocationSearch();
    }

    _hasSecondaryLocation() {
        return this.job.secondaryLocations && this.job.secondaryLocations.length > 0;
    }

    _getHelpText() {
        return i18n('search.job-item.distance-out-of-radius', { radius: this.inputRadius, unit: this.distanceUnit });
    }

}
