import ko from 'knockout';
import siteLanguageChangedService from 'ce-common/service/language/siteLanguageChanged';
import i18n from 'app/module/core/i18n/i18n';

export default class SearchInputTooltipViewModel {

    constructor({ keyword }) {
        this.isVisible = ko.observable(siteLanguageChangedService.hasChanged() && keyword());
        this.closeIconAriaLabel = i18n('apply-flow.a11y.close');
    }

    close() {
        this.isVisible(false);
        siteLanguageChangedService.dismiss();
    }

}