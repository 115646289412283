import { observable, pureComputed } from 'knockout';
import i18n from 'app/module/core/i18n/i18n';
import router from 'app/model/router';
import searchEvents from 'search/config/events';
import screenInfo from 'cx/model/screenInfo';
import modeService, { MODES } from './module/mode-menu/service/mode';
import { isZipcodeSearchEnabled, isGeolocationSearchEnabled } from 'cx/module/search/module/location/config/locationSearchConfig';

export default class LocationBarViewModel {

    constructor() {
        this.isDropdownVisible = observable(false);
        this.isFocusIn = observable(true);
        this.activeLocation = observable();
        this.activeSearchParams = pureComputed(() => router.routeParams().query || {});
        this.isBodyScrollDisabled = observable(false);
        this.locationFormName = i18n('admin.site-editor.location-search.header');

        this.mode = modeService.currentMode;
        this.MODES = MODES;

        modeService.onModeChange = () => {
            searchEvents.query.params.dispatch({
                location: null,
                zipcode: null,
                zipcodeKeyword: null,
                countryCode: null,
            });
        };

        this.isZipcodeEnabled = isZipcodeSearchEnabled();
        this.isGeolocationEnabled = isGeolocationSearchEnabled();

        this._dropdownSub = this.isDropdownVisible.subscribe((isDropdownVisible) => {
            if (screenInfo.isSmall()) {
                this.isBodyScrollDisabled(isDropdownVisible);
                searchEvents.filterPanel.toggle.dispatch(false);
            }
        });

        this._isFocusInSub = this.isFocusIn.subscribe((isFocusIn) => {
            if (!isFocusIn) {
                this.isDropdownVisible(false);
            }
        });

        this.isEmpty = pureComputed(() =>
            !(
                this.activeSearchParams().location
                || this.activeSearchParams().zipcode
                || this.activeSearchParams().workLocationZipCode
            ),
        );

        this._activeSearchParamsSub = this.activeSearchParams.subscribe(this._onActiveSearchParamsChange.bind(this));

        searchEvents.query.basicSearch.add(() => {
            this.isDropdownVisible(false);
        });

        this._setInitialLocation();
    }

    toggleDropdown() {
        this.isDropdownVisible(!this.isDropdownVisible());
    }

    clearSearchParams() {
        searchEvents.query.params.dispatch({
            radius: null,
            radiusUnit: null,
            location: null,
            zipcode: null,
            zipcodeKeyword: null,
            countryCode: null,
            mode: MODES.LOCATION,
        });

        searchEvents.clear.dispatch();
        searchEvents.query.basicSearch.dispatch(true);
    }

    _parseLocationParams(activeParams) {
        if (activeParams.locationLevel === 'city') {
            let lastSeparatorPos = activeParams.location.lastIndexOf(',');

            if (lastSeparatorPos === -1) {
                lastSeparatorPos = activeParams.location.lastIndexOf('>');
            }

            return activeParams.location.substr(0, lastSeparatorPos);
        }

        return activeParams.location;
    }

    _setInitialLocation() {
        const activeParams = this.activeSearchParams();

        this._setLocationFromParams(activeParams);
    }

    _onActiveSearchParamsChange(params) {
        this._setInitialLocation(params);
    }

    _setLocationFromParams(params) {
        this.activeLocation(this._parseLocationParams(params));
    }

    dispose() {
        this._isFocusInSub.dispose();
        this._activeSearchParamsSub.dispose();
        this._dropdownSub.dispose();
    }

}
