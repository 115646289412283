const raf = window.requestAnimationFrame;

function dragDealer(el, context) {
    let lastPageY;

    function drag(e) {
        context.target.classList.add('unselectable');

        const pageY = e.touches ? e.touches[0].pageY : e.pageY;
        const delta = pageY - lastPageY;

        lastPageY = pageY;

        const contextElement = context.el;

        raf(() => {
            contextElement.scrollTop += delta / context.scrollRatio;
        });
    }

    function stop(e) {
        context.target.classList.remove('unselectable');
        el.classList.remove('ss-grabbed');
        document.body.classList.remove('ss-grabbed');

        if (e.touches) {
            document.removeEventListener('touchmove', drag);
            document.removeEventListener('touchend', stop);
        } else {
            document.removeEventListener('mousemove', drag);
            document.removeEventListener('mouseup', stop);
        }
    }

    el.addEventListener('mousedown', (e) => {
        lastPageY = e.pageY;
        el.classList.add('ss-grabbed');
        document.body.classList.add('ss-grabbed');
        document.addEventListener('mousemove', drag);
        document.addEventListener('mouseup', stop);

        return false;
    });

    el.addEventListener('touchstart', (e) => {
        lastPageY = e.touches[0].pageY;
        el.classList.add('ss-grabbed');
        document.body.classList.add('ss-grabbed');
        document.addEventListener('touchmove', drag);
        document.addEventListener('touchend', stop);

        return false;
    });
}

function Ss(el) {
    this.target = el;
    this.barWrapper = document.createElement('div');
    this.barWrapper.setAttribute('class', 'ss-scrollbar-wrapper');
    this.bar = document.createElement('div');
    this.bar.setAttribute('class', 'ss-scroll');

    this.wrapper = document.createElement('div');
    this.wrapper.setAttribute('class', 'ss-wrapper');

    this.el = document.createElement('div');
    this.el.setAttribute('class', 'ss-content');

    this.wrapper.appendChild(this.el);

    while (this.target.firstChild) {
        this.el.appendChild(this.target.firstChild);
    }

    this.target.appendChild(this.wrapper);

    this.barWrapper.appendChild(this.bar);
    this.target.appendChild(this.barWrapper);
    this.bar = this.target.lastChild.lastChild;

    dragDealer(this.bar, this);
    this.moveBar();

    this.el.addEventListener('scroll', this.moveBar.bind(this));

    this.target.classList.add('ss-container');
    this.currentElementHeight = this.el.scrollHeight;

    this.nextTick();
}

Ss.prototype = {
    moveBar() {
        const totalHeight = this.el.scrollHeight;
        const ownHeight = this.el.clientHeight;

        this.scrollRatio = ownHeight / totalHeight;

        raf(() => {
            if (this.scrollRatio === 1) {
                this.barWrapper.classList.add('ss-hidden');
            } else {
                this.barWrapper.classList.remove('ss-hidden');

                this.bar.style.cssText = `height:${(this.scrollRatio) * 100
                }%; top:${(this.el.scrollTop / totalHeight) * 100}%;`;
            }
        });
    },

    nextTick() {
        raf(() => {
            this.updateBar();
            this.nextTick();
        });
    },

    updateBar() {
        if (this.currentElementHeight !== this.el.scrollHeight) {
            this.currentElementHeight = this.el.scrollHeight;
            this.moveBar();
        }
    },
};

function initEl(el) {
    if (Object.prototype.hasOwnProperty.call(el, 'data-simple-scrollbar')) {
        return;
    }

    Object.defineProperty(el, 'data-simple-scrollbar', new Ss(el));
}

export default {
    initializeScrollbar: initEl,
};