import 'search/module/search-box/config/module';
import 'search/module/search-header/config/module';
import 'search/module/search-results/config/module';
import 'search/module/location/config/module';
import 'search/config/routing';

import { components } from 'knockout';

import ViewModel from '../SearchViewModel';
import template from '../search.html';

components.register('search', { viewModel: ViewModel, template });
