import cxEvents from 'cx/config/events';
import unsupportedBrowser from 'cx/service/unsupported-browser/unsupportedBrowser';

export default class CustomContentLayoutViewModel {

    constructor() {
        this.isBrowserUnsupported = unsupportedBrowser.isBrowserUnsupported();
        cxEvents.loaded.dispatch();
    }

}