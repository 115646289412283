import router from 'app/model/router';
import tokenService from 'candidate-verification/service/token';
import cxHeaderEvents from '../../../../component/cx-header/config/events';
import cssEvents from 'candidate-self-service/config/events';

export default class CsHeaderButtonViewModel {

    constructor() {
        this.showDialog = this.showDialog.bind(this);
    }

    showDialog() {
        cxHeaderEvents.closeMenu.dispatch();

        if (tokenService.isCandidateVerified()) {
            router.go('candidate-self-service');
        } else {
            cssEvents.openSignInDialog.dispatch();
        }
    }

}