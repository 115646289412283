import ko from 'knockout';
import router from 'app/model/router';
import suggestionsService from 'search/module/search-box/service/suggestions';
import geolocationSuggester from 'search/module/search-box/model/geolocationSuggester';
import searchEvents from 'search/config/events';
import i18n from 'core/i18n/i18n';

const RADIUS_UNITS = {
    mi: i18n('location-bar.dropdown.radius-unit.miles'),
    km: i18n('location-bar.dropdown.radius-unit.kilometers'),
};

export default class LocationSearchMoheganViewModel {

    constructor(params) {
        this.location = params.location || ko.observable({});
        this.exactMatch = params.exactMatch || ko.observable({});
        this.locationSuggester = suggestionsService.getLocationSuggestions;
        this.geolocation = geolocationSuggester;
        this.clearButtonVisible = ko.observable();
        this.noResults = ko.observable(false);
        this.radius = ko.observable(null);
        this.radiusUnit = ko.observable(null);

        this.submitAriaLabel = ko.pureComputed(
            () => i18n('location-bar.dropdown.submit-button-description', {
                location: this.locationAsText(),
                radius: this.radius(),
                unit: this.radiusUnit(),
            }),
        );

        searchEvents.query.basicSearch.add(() => {
            const searchParams = this.activeSearchParams();

            this.clearButtonVisible(searchParams.location);
        });

        this.activeSearchParams = ko.pureComputed(() => router.routeParams().query || {}, this);

        this.isLocationLevelSet = ko.pureComputed(() =>
            this.location().level || this.exactMatch().level || this.location().isGeolocation);

        this.locationAsText = ko.pureComputed({
            read() {
                return this.location().geolocationLabel || this.location().label;
            },
            write(text) {
                this.geolocation.abort();

                this.location({
                    label: text,
                });
            },
            owner: this,
        });

        this.isGeolocation = ko.pureComputed(() => this.location().isGeolocation === true);

        this.setParamsFromRouter();

        searchEvents.query.requestGeolocation.addOnce(this.searchGeolocation.bind(this));

        searchEvents.query.params.add((queryParams) => {
            if (queryParams.radius && queryParams.radiusUnit) {
                this.radius(queryParams.radius);
                this.radiusUnit(RADIUS_UNITS[queryParams.radiusUnit.toLowerCase()] || '');
            }
        });

        searchEvents.query.params.dispatch({
            modeLoaded: true,
        });

        this.locationSub = this.location.subscribe((location) => {
            const locationParam = location.level || location.isGeolocation ? location : {};

            searchEvents.query.params.dispatch({
                location: locationParam,
                countryCode: null,
                zipcode: null,
                zipcodeKeyword: null,
            });

            this.exactMatch({});
        });

        this.activeSearchParamsSub = this.activeSearchParams.subscribe(this.setParamsFromRouter.bind(this));

        this.performSearch = (shouldRedirect) => {
            if (this.exactMatch().level) {
                this.location(this.exactMatch());
            }

            searchEvents.query.basicSearch.dispatch(shouldRedirect);
        };

        this.onSuggesterResponse = this.onSuggesterResponse.bind(this);
        this.searchGeolocation = this.searchGeolocation.bind(this);
    }

    onSuggesterResponse(e, data) {
        const results = data.content;

        this.noResults(!results.length);

        if (results.length) {
            this.exactMatch(this._checkExactMatch(e.target.value, results));
        }
    }

    searchGeolocation() {
        this.noResults(false);

        geolocationSuggester.query().then((geolocation) => {
            this.location({
                label: geolocation.name,
                latitude: geolocation.latitude,
                longitude: geolocation.longitude,
                isGeolocation: true,
            });
        });
    }

    setParamsFromRouter() {
        const searchParams = this.activeSearchParams();

        this.location({
            label: searchParams.location,
            level: searchParams.locationLevel,
            latitude: searchParams.latitude,
            longitude: searchParams.longitude,
            isGeolocation: searchParams.latitude != null && searchParams.longitude != null,
            locationId: searchParams.locationId,
        });

        this.clearButtonVisible(searchParams.location || searchParams.locationId);
    }

    _checkExactMatch(query, results) {
        if (results.length === 1) {
            return results[0];
        }

        const exactMatch = results.filter(location => query.toLowerCase() === location.label.toLowerCase());

        if (exactMatch.length === 1) {
            return exactMatch[0];
        }

        return {};
    }

    clearLocation() {
        searchEvents.query.params.dispatch({
            radius: null,
            radiusUnit: null,
            location: null,
        });

        this.location({
            label: '',
            level: '',
        });

        this.exactMatch({});

        this.clearButtonVisible(false);
        this.performSearch(true);
    }

    dispose() {
        this.locationSub.dispose();
        this.activeSearchParamsSub.dispose();
    }

}
