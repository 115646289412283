import ko from 'knockout';
import $ from 'jquery';

/**
 * Accessible clone of knockout.js 'enable' binding
 *
 * @see ko.bindingHandlers['enable']
 *
 */
ko.bindingHandlers['a11y.enable'] = {
    update(bindingElement, valueAccessor) {
        const element = bindingElement;
        const value = ko.utils.unwrapObservable(valueAccessor());

        if (value && element.disabled) {
            element.removeAttribute('disabled');
            element.removeAttribute('aria-disabled');
            $(element).trigger('a11y-disabled', false);
        } else if ((!value) && (!element.disabled)) {
            element.disabled = true;
            element.setAttribute('aria-disabled', 'true');
            $(element).trigger('a11y-disabled', true);
        }
    },
};

/**
 * Accessible clone of knockout.js 'disable' binding
 *
 * @see ko.bindingHandlers['disable']
 *
 */
ko.bindingHandlers['a11y.disable'] = {
    update(element, valueAccessor) {
        ko.bindingHandlers['a11y.enable'].update(element, () => !ko.utils.unwrapObservable(valueAccessor()));
    },
};