import ko from 'knockout';
import searchEvents from 'search/config/events';
import i18n from 'core/i18n/i18n';

export default class FilterBarViewModel {

    constructor(params) {
        this.facets = params.facets;

        const allItems = ko.pureComputed(() =>
            this.facets().reduce((items, facet) => items.concat(facet.items()), []));

        this.filters = ko.pureComputed(() => allItems().filter(item => item.selected() && !item.default));
    }

    clearFilterLinkLabel(filter) {
        return i18n('search.remove-filter-link-label', {
            filtername: filter.text,
        });
    }

    clearFilters() {
        searchEvents.query.clearFacets.dispatch();
        searchEvents.query.filterSearch.dispatch(true);
    }

    clearFilter(item, facet) {
        item.selected(false);

        searchEvents.query.params.dispatch({
            facet: ko.toJS(facet),
        });

        searchEvents.query.filterSearch.dispatch(true);
    }

}