import { components, contextFor } from 'knockout';

import ViewModel from './ApplyFlowButtonViewModel';
import template from './apply-flow-button.html';

components.register('apply-flow-button', {
    viewModel: {
        createViewModel(params, componentInfo) {
            const context = contextFor(componentInfo.element);

            return new ViewModel(params, context.$lang);
        },
    },
    template,
});
