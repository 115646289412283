import router from 'app/model/router';
import i18n from 'core/i18n/i18n';
import {
    canEnterReferralVerification,
    canEnterEmailVerification,
} from '../service/routes';
import {
    addLogger,
    removeLoggerOnVerification,
} from 'apply-flow/config/logger';

router.configure({
    'job-details.email-verification': {
        parent: 'job-details',
        url: '/apply/email',
        title: i18n('apply-flow.page-title'),
        view: {
            layout: 'apply-flow-light',
            'apply-flow-content': 'email-verification',
        },
        canEnter: canEnterEmailVerification,
        enter() {
            addLogger();
        },
        exit(routeParams, newRouteId) {
            removeLoggerOnVerification(newRouteId);
        },
    },

    'job-preview.email-verification': {
        parent: 'job-preview',
        url: '/apply/email',
        title: i18n('apply-flow.page-title'),
        view: {
            layout: 'apply-flow-light',
            'apply-flow-content': 'email-verification',
        },
        canEnter: canEnterEmailVerification,
        enter() {
            addLogger();
        },
        exit(routeParams, newRouteId) {
            removeLoggerOnVerification(newRouteId);
        },
    },

    'job-details.pin-verification': {
        parent: 'job-details',
        url: '/apply/pin',
        title: i18n('apply-flow.page-title'),
        view: {
            layout: 'apply-flow-light',
            'apply-flow-content': 'apply-flow-pin-verification',
        },
    },

    'job-details.confirm-application': {
        parent: 'job-details',
        url: '/apply/confirm',
        title: i18n('apply-flow.page-title'),
        view: {
            layout: 'apply-flow-light',
            'apply-flow-content': 'apply-flow-confirm-application',
        },
    },

    'job-details.confirm-email': {
        parent: 'job-details',
        url: '/apply/confirm-email',
        view: {
            layout: 'candidate-verification-layout',
            'candidate-verification-content': 'confirm-email',
        },
    },

    'existing-candidate-verification': {
        parent: 'job-details',
        url: '/apply/{token}',
        view: {
            layout: 'candidate-verification-layout',
            'candidate-verification-content': 'token-verification',
        },
    },

    'submission-accept': {
        parent: 'job-details',
        url: '/apply/{token}/submission/accept',
        title: i18n('apply-flow.page-title'),
        view: {
            layout: 'candidate-verification-layout',
            'candidate-verification-content': 'token-verification',
        },
    },

    'inline-assessment-verification': {
        parent: 'job-details',
        url: '/apply/inline-{partner}/{token}',
        view: {
            layout: 'candidate-verification-layout',
            'candidate-verification-content': 'inline-partner-handler',
        },
    },

    'inline-tax-credits-verification': {
        parent: 'job-details',
        url: '/apply/inline-{partner}/{token}',
        view: {
            layout: 'candidate-verification-layout',
            'candidate-verification-content': 'inline-partner-handler',
        },
    },

    'submission-not-me': {
        parent: 'job-details',
        url: '/apply/{token}/submission/not-me',
        view: {
            'candidate-verification': 'token-verification',
        },
    },

    'job-details.referral-token-verification': {
        parent: 'job-details',
        url: '/referral/{token}',
        canEnter: canEnterReferralVerification,
    },


    'share-token-verification': {
        parent: 'job-details',
        url: '/share/{shareId}',
        view: {
            'main-dialog': 'share-verification',
        },
    },

    'prospect-token-verification': {
        parent: 'job-details',
        url: '/prospect/{token}/:action:',
        view: {
            'candidate-verification': 'prospect-verification',
        },
    },


    'delete-draft': {
        parent: 'job-details',
        url: '/delete-draft/{token}',
        view: {
            'candidate-verification': 'token-verification',
        },
    },
});