import { components } from 'knockout';

import FacetViewModel from '../FacetViewModel';

import facetTemplate from '../facet.html';

components.register('categories-facet', {
    viewModel: FacetViewModel,
    template: facetTemplate,
});

components.register('locations-facet', {
    viewModel: FacetViewModel,
    template: facetTemplate,
});

components.register('workLocations-facet', {
    viewModel: FacetViewModel,
    template: facetTemplate,
});

components.register('organizations-facet', {
    viewModel: FacetViewModel,
    template: facetTemplate,
});

components.register('postingDates-facet', {
    viewModel: FacetViewModel,
    template: facetTemplate,
});

components.register('titles-facet', {
    viewModel: FacetViewModel,
    template: facetTemplate,
});

components.register('flexFields-facet', {
    viewModel: FacetViewModel,
    template: facetTemplate,
});
