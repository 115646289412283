import a11yEvents from 'core/a11y/events';
import mapping from 'knockout-mapping';
import favouriteService from 'favourite/service/favourite';
import i18n from 'core/i18n/i18n';

const JOB_UNPINNED_MESSAGE = i18n('favourite.a11y.job-unpinned');

export default class FavouritePinViewModel {

    constructor(params) {
        const job = mapping.toJS(params.job);

        this.isFavourite = favouriteService.has(job);
        this.jobTitle = job.title;
        this.onUnpinCallback = params.unpinCallback;

        this.toggleFavourite = () => {
            if (this.isFavourite()) {
                a11yEvents.alert.dispatch(JOB_UNPINNED_MESSAGE);

                setTimeout(() => {
                    favouriteService.remove(job);

                    if (this.onUnpinCallback) {
                        this.onUnpinCallback();
                    }
                }, 700);
            } else {
                favouriteService.add(job);
            }
        };
    }

}