import { observable } from 'knockout';
import i18n from 'core/i18n/i18n';
import extraInformationTileFormBuilder from '../../form/extraInformationTileFormBuilder';
import TileProfileItemsViewModel from '../../../tile-profile-items/TileProfileItemsViewModel';
import sectionState from 'apply-flow/model/sectionState';
import sectionValidator from 'apply-flow/model/sectionValidator';

export default class ExtraInformationTileFormViewModel extends TileProfileItemsViewModel {

    constructor(params) {
        super(params);

        const { model, title, runCustomJsValidation } = params;

        this.model = model;
        this.profileItems = this._getCandidateProfileItems();

        this.addButtonLabel = `${i18n('apply-flow.profile-item.add-item-button')} ${title}`;
        this.showAddButton = observable(true);
        this.showRemoveButton = observable(true);
        this.runCustomJsValidation = runCustomJsValidation;

        this.subscribeToProfileItems();
        this._createFormsFromModel();
    }

    addProfileItem() {
        return this._pushProfileItem()
            .then((form) => {
                this.editProfileItem(form);
            });
    }

    removeProfileItem(contextRow) {
        this.model.remove(contextRow.model());
        this.runCustomJsValidation();
    }

    _createSingleForm(contextRow) {
        const form = extraInformationTileFormBuilder.createForm({
            elements: this.model.formElements,
            existingRows: this.model.rows,
        });

        form.registerModel(contextRow.attributes);

        return observable(form);
    }

    _createFormsFromModel() {
        this.model.rows().forEach(row => this._insertSingleForm(row));
        this._buildTiles();
    }

    _insertSingleForm(contextRow) {
        const form = this._createSingleForm(contextRow);

        this.forms.push(form);

        sectionValidator.registerForm(form, this.sectionNumber);

        return form;
    }

    _fetchFormElements() {
        return Promise.resolve();
    }

    _getCandidateProfileItems() {
        return this.model && this.model.rows;
    }

    _getMetadata() {
        this.metadataService = {
            getFormElements: () => Promise.resolve(this._formElements()),
        };
    }

    _loadForms() {
        return this._fetchFormElements()
            .then(() => {
                if (this._shouldBuildTilesOnInit) {
                    this._buildTiles();
                }

                this._formsSub = this.forms.subscribe(() => this._buildTiles(), this, 'arrayChange');
            })
            .then(() => sectionState.setBlockReady(this.sectionNumber, this.blockId));
    }

    _removeForms(profileItemsChanges) {
        profileItemsChanges
            .filter(arrayChange => arrayChange.status === 'deleted')
            .map(arrayChange => arrayChange.value)
            .forEach(profileItem => this._removeSingleForm(profileItem));
    }

    _addForms(profileItemsChanges) {
        profileItemsChanges
            .filter(arrayChange => arrayChange.status === 'added')
            .map(arrayChange => arrayChange.value)
            .forEach(profileItem => this._insertSingleForm(profileItem));
    }

    _refreshProfileItems() {
        return Promise.resolve();
    }

    _removeSingleForm(contextRow) {
        const [removedForm] = this.forms().filter(form => form().model() === contextRow.attributes);

        this.forms.remove(removedForm);
        sectionValidator.unregisterForm(removedForm, this.sectionNumber);
    }

    _pushProfileItem() {
        return new Promise((resolve) => {
            this.forms.subscribeOnce((changes) => {
                const form = changes
                    .filter(change => change.status === 'added')
                    .map(change => change.value())
                    .pop();

                resolve(form);
            }, this, 'arrayChange');

            this.model.addRow();
        });
    }

    validateProfileItemForm(profileItemForm) {
        profileItemForm.enableImmediateValidation();

        return profileItemForm.validate()
            .then((isValid) => {
                if (isValid) {
                    return Promise.resolve();
                }

                this.focusInvalidElement();

                return Promise.reject();
            })
            .then(this.runCustomJsValidation);
    }

}