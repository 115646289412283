import ko from 'knockout';
import Hammer from 'hammerjs';

/**
 * Minimal delta to switch isVisible observable.
 *
 * @constant
 * @type {number}
 */

const MINIMAL_DELTA_TO_HIDE = 100;

/**
 * Possible directions.
 *
 * @constant
 * @type {object}
 */

const DIRECTIONS = {
    RIGHT: 'right',
    LEFT: 'left',
};

/**
 * Add hammer js pan events to element. Switch isVisible observable when minimal delta is reached.
 *
 * @param {observable} isVisible - ko observable switched when minimal delta is reached
 * @param {string} direction - direction, could be left or right
 *
 * @example
 * <div data-bind="hideableAside: { isVisible: isMenuOpen, direction: 'left/right' }"></div>
 */

ko.bindingHandlers.hideableAside = {
    init(bindingElement, accessor) {
        const element = bindingElement;
        const { isVisible } = accessor();
        const direction = accessor().direction || DIRECTIONS.RIGHT;

        const hamburgerMenu = new Hammer(element, {
            inputClass: Hammer.TouchInput,
        });

        let absoluteDeltaX = 0;
        const initialTransition = element.style.transition;

        function onPanStart() {
            element.style.transition = 'all 0s';
        }

        function hidingPan(deltaToMove) {
            absoluteDeltaX = Math.abs(deltaToMove);
            element.style.transform = `translateX(${deltaToMove}px)`;
        }

        function onHidingRight(ev) {
            hidingPan(ev.deltaX > 0 ? ev.deltaX : 0);
        }

        function onHidingLeft(ev) {
            hidingPan(ev.deltaX < 0 ? ev.deltaX : 0);
        }

        function onPanEnd() {
            element.style.transform = '';
            element.style.transition = initialTransition;
            isVisible(absoluteDeltaX < MINIMAL_DELTA_TO_HIDE);
        }

        hamburgerMenu.on('panstart', onPanStart);

        if (direction === DIRECTIONS.RIGHT) {
            hamburgerMenu.on('panleft panright', onHidingRight);
        }

        if (direction === DIRECTIONS.LEFT) {
            hamburgerMenu.on('panleft panright', onHidingLeft);
        }

        hamburgerMenu.on('panend', onPanEnd);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            hamburgerMenu.destroy();
        });
    },
};