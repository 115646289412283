import router from 'app/model/router';

router.configure({
    'campaign-thank-you': {
        parent: 'home-page',
        url: 'campaign/thank-you',
        view: {
            'main-dialog': 'campaign-thank-you',
        },
    },
    'campaign-unsubscribe': {
        parent: 'home-page',
        url: 'unsubscribe/{campaignCode}/token/{token}',
        view: {
            'main-dialog': 'campaign-unsubscribe',
        },
    },
});