import ko from 'knockout';
import favouriteService from 'favourite/service/favourite';
import favouriteEvents from 'favourite/config/events';

export default class FavouriteNavbarBtnViewModel {

    constructor() {
        this.isPanelVisible = ko.observable(false);

        favouriteEvents.openPanel.add(this.isPanelVisible.bind(this, true));
        favouriteEvents.closePanel.add(this.isPanelVisible.bind(this, false));

        this.isNavbarBtnVisible = ko.pureComputed(() => favouriteService.length() || this.isPanelVisible());
        this.numOfFavorites = favouriteService.length;

        this.togglePanel = this.togglePanel.bind(this);
    }

    togglePanel() {
        if (this.isPanelVisible()) {
            favouriteEvents.closePanel.dispatch();
        } else {
            favouriteEvents.openPanel.dispatch();
        }
    }

}