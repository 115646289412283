import router from 'app/model/router';
import absoluteRouter from 'app/model/absoluteRouter';
import cxSignals from 'cx/config/events';
import siteLanguage from 'ce-common/service/language/siteLanguage';
import userTracking from 'cx/service/userTracking';
import userEvents, { APPLY_FLOW_ENTERED } from 'cx/config/userEvents';
import { getApplyFlowRouting } from 'candidate-verification/service/routes';

export default class ApplyFlowButtonViewModel {

    constructor({ jobId }, lang) {
        this.jobId = jobId;
        this.lang = lang;
        this._isJobPreview = false;
    }

    _redirect({ route, params }) {
        const siteLang = siteLanguage.get();

        if (siteLang !== this.lang) {
            const url = absoluteRouter.interpolate({ lang: this.lang }, route, params);

            return router.redirect(url);
        }

        return router.go(route, params);
    }

    async apply() {
        cxSignals.toggleLoading.dispatch('apply-now-button');

        userEvents[APPLY_FLOW_ENTERED].dispatch({
            jobId: this.jobId(),
        });

        siteLanguage.store();
        userTracking.trackJobApplicationStart(this.jobId());

        const flowRouting = await getApplyFlowRouting(this.jobId(), this._isJobPreview);

        this._redirect(flowRouting);
    }

}