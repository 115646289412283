import { observable } from 'knockout';
import router from 'app/model/router';
import { noOperation } from 'core/utils/noOperation';
import geolocationService from 'cx/service/geolocation/geolocation';
import searchEvents from 'search/config/events';

class ZipcodeSearchViewModel {

    constructor() {
        this.zipcode = observable();
        this.radiusValue = null;
        this.countryCode = null;

        this.isWorking = observable(false);
        this.showNoResultsHint = observable(false);
        this.showNoSelectedCountry = observable(false);
        this.hasFocus = observable(false);

        this._searchParamsChangeSignal = searchEvents.query.params.add(this._onParamsChange.bind(this));
        this._clearSignal = searchEvents.clear.add(this._onClear.bind(this));
        searchEvents.radius.set.dispatch(25);
        searchEvents.radius.enable.dispatch(true);

        this._hasFocusSubscription = this.hasFocus.subscribe(this._onFocus.bind(this));

        this._setInitialValue();
        this._getGeolocationZipcode();
    }

    async performSearch() {
        if (!this.countryCode) {
            this.showNoSelectedCountry(true);

            return;
        }

        try {
            if (this.radiusValue > 0) {
                searchEvents.query.params.dispatch({
                    location: await this._resolveZipcode(),
                    zipcode: null,
                    zipcodeKeyword: this.zipcode(),
                });
            } else {
                searchEvents.query.params.dispatch({
                    isGeolocation: true,
                    zipcode: this.zipcode(),
                    zipcodeKeyword: null,
                });
            }

            searchEvents.query.basicSearch.dispatch(true);
        } catch (error) {
            if (error instanceof Error) {
                console.error(error);
            }
        }
    }

    async _getGeolocationZipcode() {
        if (!this.zipcode()) {
            this.isWorking(true);

            try {
                const { zipCode } = await geolocationService.query();

                this.zipcode(zipCode);
            } catch (error) {
                noOperation(error);
            } finally {
                this.isWorking(false);
            }
        }
    }

    async _resolveZipcode() {
        const zipcode = this.zipcode();

        this.isWorking(true);

        try {
            const {
                latitude,
                longitude,
            } = await geolocationService.getLocationByZipcode(zipcode, this.countryCode);

            return { latitude, longitude, isGeolocation: true };
        } catch (error) {
            this.showNoResultsHint(true);

            throw error;
        } finally {
            this.isWorking(false);
        }
    }

    _onParamsChange({ radius, countryCode }) {
        if (radius !== undefined) {
            this.radiusValue = radius;
        }

        if (countryCode !== undefined) {
            this.showNoSelectedCountry(false);
            this.countryCode = countryCode;
        }
    }

    _onClear() {
        this.zipcode('');
        this.radiusValue = null;
        this.countryCode = null;
        this.showNoSelectedCountry(false);
    }

    _onFocus(hasFocus) {
        if (hasFocus) {
            this.showNoResultsHint(false);
        }
    }

    _setInitialValue() {
        const { query } = router.routeParams();

        if (query && (query.zipcode || query.workLocationZipCode)) {
            this.zipcode(query.zipcode || query.workLocationZipCode);
        }
    }

    dispose() {
        searchEvents.radius.enable.dispatch(false);
        this._searchParamsChangeSignal.detach();
        this._hasFocusSubscription.dispose();
        this._clearSignal.detach();
    }

}

export default ZipcodeSearchViewModel;
