import ko from 'knockout';
import i18n from 'core/i18n/i18n';
import searchEvents from 'search/config/events';
import { isAnySelectionOutOfLimit } from './service/isAnySelectionOutOfLimit';

const DEFAULT_LIMIT = 5;

export default class FacetViewModel {

    constructor(params) {
        this.facet = params.data;

        this.textFilter = ko.observable('');
        this.filterHasFocus = ko.observable(false);

        this.ariaLabel = this.facet.name === 'flexFields' ? this.facet.title : i18n(`facets.${this.facet.name.toLowerCase()}-title`);

        this.title = this.facet.title || i18n(`facets.${this.facet.name.toLowerCase()}-title`);

        this.getFormattedId = () => this.title.toLowerCase().replace(/ /g, '_');

        this.showAll = ko.observable(false);
        this.expanded = ko.observable(this._hasSelectedItems());

        this.lastClickedItemId = '';

        this.toggleShowAll = () => {
            this.showAll(!this.showAll());

            if (this.showAll()) {
                this.filterHasFocus(true);
            }
        };

        this.toggleFacetList = () => {
            this.expanded(!this.expanded());
        };

        this.switchFacet = (item) => {
            item.selected(!item.selected());
            this.lastClickedItemId = item.value;
        };

        this.itemClicked = (item) => {
            this.switchFacet(item);

            searchEvents.query.params.dispatch({
                facet: ko.toJS(this.facet),
            });

            searchEvents.query.filterSearch.dispatch(true);
        };

        this.blurHandler = () => {
            this.lastClickedItemId = '';
        };

        this.isItemFocused = item => item && item.value === this.lastClickedItemId;

        this.displayedItems = ko.pureComputed(() => {
            if (!this.showAll() || !this.textFilter().length) {
                return this.facet.items().slice(0, this.limit());
            }

            return this.facet.items()
                .filter(item => item.text.toLowerCase()
                    .indexOf(this.textFilter().toLowerCase()) === 0);
        });

        this.limit = ko.pureComputed(() => {
            if (this.showAll()) {
                return this.facet.items().length;
            }

            return DEFAULT_LIMIT;
        });

        this.hasMore = ko.pureComputed(() => this.facet.items().length > DEFAULT_LIMIT);

        this.showAll(isAnySelectionOutOfLimit({
            items: this.facet.items(),
            selectedItems: this.facet.selectedItems(),
            limit: DEFAULT_LIMIT,
        }));
    }

    _hasSelectedItems() {
        const selectedItems = this.facet.selectedItems()
            .filter(item => !item.default);

        return Boolean(selectedItems.length);
    }

    onPressEnter(viewModel, event) {
        event.preventDefault();
    }

}