import ko from 'knockout';
import router from 'app/model/router';
import searchService from 'search/service/search';
import RecentSearches from 'search/service/RecentSearches';
import searchEvents from 'search/config/events';
import BasicSearchHandler from 'search/model/BasicSearchHandler';
import FacetSearchHandler from 'search/model/FacetSearchHandler';

const SEARCH_DELAY = 1000; // 1s

export default class SearchViewModel {

    constructor(params) {
        const recentSearches = new RecentSearches();
        const basicSearchHandler = new BasicSearchHandler();
        const facetSearchHandler = new FacetSearchHandler();

        function initializeHandlers(routeParams) {
            basicSearchHandler.initialize(ko.unwrap(routeParams).query);
            facetSearchHandler.initialize(ko.unwrap(routeParams).query);
        }

        initializeHandlers(router.routeParams);

        this.routeParamsSub = router.routeParams.subscribe(initializeHandlers);
        this.renderHeader = (params && params.renderHeader !== undefined) ? params.renderHeader : true;
        this.renderMain = (params && params.renderMain !== undefined) ? params.renderMain : true;

        searchEvents.query.basicSearch.add((shouldRedirect) => {
            const query = basicSearchHandler.buildQuery();

            recentSearches.saveSearch(query);
            searchEvents.query.search.dispatch(query, shouldRedirect);
        });

        function _redirectAndSearch(shouldRedirect) {
            const query = facetSearchHandler.buildQuery();

            searchEvents.query.search.dispatch(query, shouldRedirect);
        }

        searchEvents.query.filterSearch.add((shouldRedirect) => {
            this._queueSearch(_redirectAndSearch.bind(this, shouldRedirect));
        });

        searchEvents.query.offsetSearch.add(() => {
            this._search(facetSearchHandler.buildQuery());
        });

        searchEvents.query.search.add((query, shouldRedirect) => {
            router.go('search', { query }, {
                inherit: !shouldRedirect,
            }).then(() => {
                const { query: routerQuery } = router.routeParams();

                return this._search(routerQuery);
            });
        });

        searchEvents.pageLoaded.add(() => {
            this._queueSearch(this._search.bind(this, facetSearchHandler.buildQuery()));
        });
    }

    _queueSearch(searchCallback) {
        let _timeoutId = null;

        return new Promise((resolve) => {
            if (_timeoutId) {
                clearTimeout(_timeoutId);
            }

            _timeoutId = setTimeout(() => {
                searchCallback();
                resolve();
            }, SEARCH_DELAY);
        });
    }

    _search(query) {
        searchEvents.results.searchFired.dispatch();

        return searchService.searchJobsAndFacets(query)
            .then((response) => {
                searchEvents.results.jobsUpdated.dispatch({
                    jobs: response.requisitionList,
                    totalCount: response.totalJobsCount,
                    hasMore: response.hasMore,
                });

                searchEvents.results.facetsUpdated.dispatch(response.facets);
                searchEvents.results.sortByUpdated.dispatch(response.sortBy);
            })
            .catch(searchEvents.results.searchFailed.dispatch);
    }

    dispose() {
        this.routeParamsSub.dispose();

        Object.keys(searchEvents.query).forEach((signalName) => {
            searchEvents.query[signalName].removeAll();
        });

        searchEvents.pageLoaded.removeAll();
    }

    _toggleSignals(active) {
        searchEvents.query.basicSearch.active = active;
        searchEvents.query.filterSearch.active = active;
        searchEvents.query.offsetSearch.active = active;
        searchEvents.pageLoaded.active = active;
    }

}
