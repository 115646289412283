import { observable, observableArray, pureComputed } from 'knockout';
import router from 'app/model/router';

const RECOMMENDED_JOBS_CONTAINER_SELECTOR = '.joblist-grid';
const RECOMMENDED_JOB_LIST_ELEMENT_SELECTOR = '.joblist-tile';

export default class AiJobsSearchAbstractViewModel {

    constructor({ requisitionId, className, isReady }) {
        this.jobs = observableArray();
        this.loading = observable(true);
        this.initialized = observable(false);
        this.loadingMore = observable(false);
        this.hasMoreResults = observable(false);
        this.showResults = pureComputed(this._showResults, this);
        this.jobRoute = this._getJobRoute();
        this.requisitionId = requisitionId;
        this.offset = 0;
        this.className = className;
        this.header = this.getHeaderLabel();
        this.isReady = isReady ?? observable(false);

        this.loadJobs();
    }

    getJobs() {
        throw new Error('Method not implemented');
    }

    getHeaderLabel() {
        throw new Error('Method not implemented');
    }

    loadJobs() {
        this.loading(true);

        this.getJobs({}, this.requisitionId)
            .then(({ requisitionList, hasMore }) => {
                this.jobs(requisitionList);
                this.initialized(true);
                this.hasMoreResults(hasMore);
            })
            .catch(this.handleError.bind(this))
            .finally(this._onJobsLoaded.bind(this));
    }

    loadMoreJobs() {
        const query = { offset: ++this.offset };
        const currentListElementsLength = this.jobs().length;

        this.loadingMore(true);

        this.getJobs(query, this.requisitionId)
            .then((response) => {
                this._onMoreJobsLoaded(response);
                this._setFocusOnNextJobListElement(currentListElementsLength);
            })
            .catch((error) => {
                console.error(error);
            })
            .then(() => this.loadingMore(false));
    }

    handleError(error) {
        console.error(error);
    }

    _onJobsLoaded() {
        this.loading(false);
        this.isReady(true);
    }

    _onMoreJobsLoaded({ requisitionList, hasMore }) {
        this.jobs.push(...requisitionList);
        this.hasMoreResults(hasMore);
    }

    _showResults() {
        return !this.loading() && this._hasResults();
    }

    _hasResults() {
        return this.initialized() && this.jobs().length > 0;
    }

    _getJobRoute() {
        if (router.route().id === 'job-details' || router.route().id === 'job-full-view') {
            return 'job-details';
        }

        return 'job-preview';
    }

    _setFocusOnNextJobListElement(index) {
        const parentElement = document.querySelector(RECOMMENDED_JOBS_CONTAINER_SELECTOR);
        const nextChild = parentElement.querySelectorAll(RECOMMENDED_JOB_LIST_ELEMENT_SELECTOR)[index];

        if (nextChild) {
            nextChild.focus();
        }
    }

}