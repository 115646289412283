import ko from 'knockout';

/**
 * Changes visibility of element to 'hidden' when observable given in parameter changes to false
 * and transition ends. This is done to prevent the ability to access elements inside the container using
 * TAB key.
 *
 * @param {observable} isVisible - observable responsible for target visibility
 *
 * @example
 *   <div class="closed"
 *      data-bind="click: toggleMe, css: {'opened-with-animation': isOpen},
 *                  hideAfterAnimation: {isVisible: isOpen}">
 *      <button>Click me</button> <!-- won't be accessible using tab, but animation will still work fine -->
 *   </div>
 */
ko.bindingHandlers['a11y.hideAfterAnimation'] = {
    init(bindingElement, valueAccessor) {
        const element = bindingElement;
        const { isVisible } = valueAccessor();

        function hideElementIfNotVisible() {
            if (!isVisible()) {
                element.style.visibility = 'hidden';
            }
        }

        hideElementIfNotVisible();

        ko.utils.registerEventHandler(element, 'transitionend', hideElementIfNotVisible);

        const subscription = isVisible.subscribe((value) => {
            if (value) {
                element.style.visibility = 'visible';
            }
        }, 'beforeChange');

        ko.utils.domNodeDisposal.addDisposeCallback(element, subscription.dispose.bind(subscription));
    },
};