import router from 'app/model/router';

router.configure({
    cx: {
        abstract: true,
        view: {
            layout: 'cx',
            header: 'cx-header',
            'candidate-verification': null,
            footer: 'cx-footer',
        },
    },
});
