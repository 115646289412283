import ko from 'knockout';
import jobService from 'job-details/service/job';
import router from 'app/model/router';
import searchEvents from 'search/config/events';
import cxEvents from 'cx/config/events';
import appConfig from 'app/model/config';
import userTracking from 'cx/service/userTracking';
import googleJobPostingConverter from 'job-details/service/googleJobPostingConverter';
import { isCopyLinkVisible } from 'job-details/service/copyLinkVisibility';
import userEvents, { JOB_VIEWS } from 'cx/config/userEvents';
import historyService from 'ce-common/service/historyService';

export default class JobDetailsAbstractViewModel {

    constructor(params = {}) {
        this.job = ko.observable();
        this.loaded = ko.observable(false);
        this._closeJobDetailsCallback = params.closeJobDetails;
        this.googleJobSearchMarkup = ko.observable();

        this.isCopyLinkEnabled = ko.observable();
        this.isGJSEnabled = ko.observable(appConfig.getSettingByKey('IRC_CE_GJS_ENABLED'));

        this.jobId = ko.pureComputed(() => router.routeParams().jobId);
        this.isJobExpired = ko.pureComputed(() => router.routeParams().isExpired);

        this.jobTileSelector = ko.pureComputed(() => `[data-job-tile='${this.jobId()}']`);

        this.hasMedia = ko.pureComputed(() => Boolean(this.job().media.length));

        this.isJobPreview = ko.pureComputed(() => {
            const route = router.route();

            return route.id === 'job-preview' || (route.parent && route.parent.id === 'job-preview');
        });

        this.isJobPreviewOn = ko.pureComputed(() => {
            if ('isJobPreviewOn' in params) {
                return params.isJobPreviewOn;
            }

            return true;
        });

        this.loadJob()
            .then(this._handleConfidential.bind(this))
            .then(this._setCopyLinkVisibility.bind(this))
            .then(this._generateGoogleJobSearchStr.bind(this))
            .catch(console.error);

        this._jobSubscription = this.job.subscribe(() => {
            if (this.job()) {
                this._updatePageTitle();
            }
        });

        this._routeParamsSubscription = router.routeParams.subscribe(() => {
            this.loadJob();
        });

        this.showSecondaryLocations = ko.pureComputed(() => {
            const job = this.job();

            return job.secondaryLocations && job.secondaryLocations.length > 0;
        });
    }

    loadJob() {
        this.loaded(false);
        this.job(null);
        userTracking.trackJobView(this.jobId());

        return this._checkIfExpired()
            .then(() => this._getJob(this.jobId()))
            .then(job => this.job(job))
            .catch(err => console.error(err))
            .then(() => this.loaded(true))
            .then(() => userEvents[JOB_VIEWS].dispatch())
            .then(() => {
                if (!this.job()) {
                    throw new Error('Job not found');
                }
            });
    }

    _checkIfExpired() {
        return this.isJobExpired() ? Promise.reject() : Promise.resolve();
    }

    closeJobDetails() {
        if (this._closeJobDetailsCallback) {
            searchEvents.jobDetails.isClosing.dispatch(true);

            return this._closeJobDetailsCallback();
        }

        const routeParams = router.routeParams();

        return historyService.moveBackOrFallbackTo('search', {
            query: routeParams.query,
            page: routeParams.page,
        }, {
            inherit: false,
        }).then(() => searchEvents.jobDetails.isClosing.dispatch(true));
    }

    dispose() {
        this._jobSubscription.dispose();
        this._routeParamsSubscription.dispose();
    }

    _getJob(jobId) {
        switch (router.route().id) {
            case 'job-formatting-preview':
                return jobService.getJobPreview(jobId);

            case 'job-template-preview':
                return jobService.getJobTemplatePreview(jobId);

            default:
                return jobService.getJob(jobId);
        }
    }

    async _handleConfidential() {
        this.loaded(false);

        if (this.job().isConfidential) {
            this.isGJSEnabled(false);
        }

        this.loaded(true);
    }

    _setCopyLinkVisibility() {
        isCopyLinkVisible({
            routeId: router.route().id,
            jobId: this.job().id,
        })
            .then(this.isCopyLinkEnabled);
    }

    _updatePageTitle() {
        if (!this.isJobPreview()) {
            cxEvents.pageTitleUpdate.dispatch(this.job().title);
        }
    }

    _removeHTML(str) {
        const tmp = document.createElement('div');

        tmp.innerHTML = str;

        return tmp.textContent || tmp.innerText || '';
    }

    _removeQuotationMark(str) {
        return str.replace('"', '');
    }

    _generateGoogleJobSearchStr() {
        if (!this.isGJSEnabled()) {
            return '';
        }

        return googleJobPostingConverter.generateMarkup(this.job())
            .then(str => this.googleJobSearchMarkup(str));
    }

}
