import ko from 'knockout';
import AutosuggestFormElementViewModel from './AutosuggestFormElementViewModel';

export default class AutosuggestFormElementMoheganViewModel extends AutosuggestFormElementViewModel {

    constructor(...args) {
        super(...args);

        this.placeholder = ko.pureComputed(() => {
            const isEmpty = this.isEmpty();
            const hasFocus = this.hasFocus();

            if (!hasFocus || isEmpty) {
                // must be at least 1 whitespace to work
                return ' ';
            }

            return this.defaultPlaceholder;
        });
    }

}
