import './routing';
import '../component/apply-flow-header-mock/component';
import '../component/apply-flow-primary-color-mock/component';
import '../component/apply-flow-text-mock/component';
import '../component/cx-header-mock/component';
import '../component/cx-footer-mock/component';
import '../component/primary-button-mock/component';
import '../component/quick-view-button-mock/component';
import '../component/view-all-selections-button-mock/component';
import '../component/view-all-jobs-button-mock/component';
import '../component/internal-user-login-button-mock/component';
import '../component/autocomplete-mock/component';
import '../component/show-more-results-button-mock/component';
import '../component/job-expired-mock/component';
import '../component/general-error-mock/component';
import '../component/job-loading-bar-mock/component';
import '../component/radio-button-active-mock/component';
import '../component/slider-mock/component';
import '../component/filter-view-all-mock/component';
import '../component/recent-searches-dropdown-mock/component';
import '../component/mobile-title-mock/component';
import '../component/mobile-menu-mock/component';
import '../component/search-result-item-mock/component';
import '../component/welcome-text-mock/component';
import '../component/search-box-input-form-mock/component';
import '../component/search-box-mock/component';
import '../component/filter-bar-mock/component';
import '../component/filter-panel-mock/component';
import '../component/job-details-mock/component';
import '../component/favourite-pin-mock/component';
import '../component/filter-checkbox-active-mock/component';
import '../component/job-information-bar-mock/component';
import '../component/location-search-mock/component';
import '../component/mobile-search-box-mock/component';
import '../component/mobile-job-selections-mock/component';
import '../component/mobile-filters-mock/component';
import '../component/mobile-search-mock/component';

import { components } from 'knockout';

import ViewModel from '../ThemeEditableElementsListViewModel';
import template from '../theme-editable-elements-list.html';

components.register('theme-editable-elements-list', { viewModel: ViewModel, template });
