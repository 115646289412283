import ko from 'knockout';
import storageService from 'core/storage/sessionStorage';
import jobService from 'job-details/service/job';
import siteLanguage from 'ce-common/service/language/siteLanguage';
import userTracking from 'cx/service/userTracking';
import favouriteEvents from 'favourite/config/events';

const FAVOURITE_IDS_STORAGE_KEY = 'favourite-jobs-ids';
const FAVOURITE_STORAGE_KEY = `favourite-jobs_${siteLanguage.get()}`;

const storedFavouriteJobs = storageService.restore(FAVOURITE_STORAGE_KEY) || [];
const favouriteJobs = ko.observableArray();

favouriteJobs.subscribe(jobs => storageService.store(FAVOURITE_IDS_STORAGE_KEY, jobs.map(job => job.id)));
favouriteEvents.favouriteJobsSet.add(setFavouriteJobs);
setFavouriteJobs();

function setFavouriteJobs() {
    const storedJobsIds = storageService.restore(FAVOURITE_IDS_STORAGE_KEY);

    if (!storedJobsIds) {
        return;
    }

    jobService.getJobs(storedJobsIds)
        .then(({ items }) => favouriteJobs(items));
}

function storeFavouriteJob(job) {
    if (!storedFavouriteJobs.some(storedJob => storedJob.id === job.id)) {
        storedFavouriteJobs.unshift(job);
        storageService.store(FAVOURITE_STORAGE_KEY, storedFavouriteJobs);
    }

    return job;
}

export default {
    query() {
        return favouriteJobs;
    },

    length: ko.pureComputed(() => favouriteJobs().length, this),

    has(job) {
        return ko.pureComputed(() => favouriteJobs().some(favouriteJob => favouriteJob.id === job.id));
    },

    add(job) {
        favouriteJobs.unshift(job);
        storeFavouriteJob(job);
        userTracking.trackAddRequisitionToFavourites(job);

        return this;
    },

    remove(job) {
        favouriteJobs.remove(favouriteJob => favouriteJob.id === job.id);
        userTracking.trackRemoveRequisitionToFavourites(job);

        return this;
    },

    clear() {
        favouriteJobs.removeAll();
    },
};