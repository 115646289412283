import { observable, pureComputed, unwrap } from 'knockout';
import copyAbilityResolver from 'job-details/component/copy-link/service/copyAbilityResolver';
import deviceDetector from 'core/device-detector/deviceDetector';
import linkGenerator from 'cx/service/linkGenerator';
import { SEARCH_RESULTS_STRINGS } from '../../../search/config/types';

const COPY_DELAY = 3000;

const canCopyProgrammatically = copyAbilityResolver.canCopyProgramatically();
const canCopyByShortcut = copyAbilityResolver.canCopyByShortcut();
const isMac = deviceDetector.isMac();

export default class CopyLinkViewModel {

    constructor(jobId, lang) {
        this.isCopied = observable(false);
        this.jobLink = linkGenerator.generate(unwrap(jobId), lang, SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_JOBS);

        this.copyButtonLabel = pureComputed(() => {
            if (this.isCopied()) {
                if (canCopyProgrammatically) {
                    return 'job-details.link-copied-label';
                }

                if (canCopyByShortcut && isMac) {
                    return 'job-details.link-copy-instruction-mac';
                }

                if (canCopyByShortcut && !isMac) {
                    return 'job-details.link-copy-instruction';
                }

                return 'job-details.link-selected-label';
            }

            return 'job-details.copy-link-button';
        });
    }

    setIsCopied() {
        this.isCopied(true);

        setTimeout(() => {
            this.isCopied(false);
        }, COPY_DELAY);
    }

}