import ko from 'knockout';
import cxEvents from 'cx/config/events';


const LOADING_CLASS = 'app-loading';
const LOADED_CLASS = 'app-loaded';

/**
 * Adds loading spinner to element when the application is loading
 *
 * @example
 * <body class="app-loading" data-bind="loadingIndicator">
 */
ko.bindingHandlers.loadingIndicator = {
    init(element) {
        cxEvents.loaded.add(() => {
            ko.utils.toggleDomNodeCssClass(element, LOADING_CLASS, false);
            ko.utils.toggleDomNodeCssClass(element, LOADED_CLASS, true);
        });

        cxEvents.loading.add(() => {
            ko.utils.toggleDomNodeCssClass(element, LOADING_CLASS, true);
            ko.utils.toggleDomNodeCssClass(element, LOADED_CLASS, false);
        });
    },
};
