import AiJobsSearchAbstractViewModel from '../search-ai-jobs/AiJobsSearchAbstractViewModel';
import searchService from 'search/service/search';
import i18n from 'core/i18n/i18n';
import notificationsService from 'cx/service/notifications';

export default class SearchJobsToCandidateViewModel extends AiJobsSearchAbstractViewModel {

    getJobs(query) {
        return searchService.searchJobsToCandidate(query, this.requisitionId);
    }

    getHeaderLabel() {
        return 'search.search-jobs-to-candidate.suggested-jobs-header';
    }

    handleError(error) {
        super.handleError(error);

        notificationsService.error(i18n('candidate-self-service.jobs-to-candidates-loading-error'));
    }

}