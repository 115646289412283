import router from 'app/model/router';
import tokenService from 'candidate-verification/service/token';
import i18n from 'core/i18n/i18n';
import { cssActionsRoutes, cssTokenRoute } from './commonRoutes';
import { canEnterCSS } from './canEnterCss';

router.configure({
    'candidate-self-service': {
        url: '/my-profile',
        view: {
            layout: 'candidate-self-service',
            dialogs: null,
        },
        title: i18n('candidate-self-service.page-title'),
        canEnter() {
            return canEnterCSS();
        },
    },

    'candidate-self-service.sign-in': {
        parent: 'cx',
        url: '/my-profile/sign-in',
        canEnter() {
            if (!tokenService.isCandidateVerified()) {
                return Promise.resolve();
            }

            return Promise.reject({
                redirectTo: 'candidate-self-service',
            });
        },
    },

    'candidate-self-service.job-preview': {
        parent: 'candidate-self-service',
        url: '/preview/{jobId}',
        view: {
            dialogs: 'job-details-css',
        },
        canEnter() {
            return canEnterCSS();
        },
    },

    ...cssTokenRoute,
    ...cssActionsRoutes,
});
