import ko from 'knockout';
import { propertyEquals } from 'core/utils/functional/propertyEquals';
import moheganTemplateConfig from './template/mohegan.json';
import minimalTemplateConfig from './template/minimal.json';

function _getConfigForTemplate(templateNumber) {
    switch (templateNumber) {
        case 'mohegan':
            return moheganTemplateConfig;
        case 'minimal':
            return minimalTemplateConfig;
        default:
            return undefined;
    }
}

export default {
    getModelForTemplate(templateNumber) {
        const templateConfig = _getConfigForTemplate(templateNumber);

        if (!templateConfig) {
            return null;
        }

        return ko.utils.extend({}, JSON.parse(JSON.stringify(templateConfig)));
    },

    getTemplateFonts(templateNumber) {
        const styleModel = this.getModelForTemplate(templateNumber);

        const primaryFont = styleModel.sections.find(
            propertyEquals('id', 'theme-font-primary'),
        );

        return primaryFont.options;
    },
};