import ko from 'knockout';
import cxEvents from 'cx/config/events';
import router from 'app/model/router';
import appConfig from 'app/model/config';
import themeStylesConfig from '../../config/themeStylesConfiguration';

export default class ThemeEditableElementsPreviewViewModel {

    constructor() {
        this.sectionId = ko.pureComputed(() => router.routeParams().sectionId);

        this.groupId = ko.pureComputed(() => router.routeParams().groupId);

        this.showNoGroupSelectWarning = ko.pureComputed(() => !this.groupId() || !this.sectionId());

        function getPreviewComponents(sectionId, groupId) {
            const stylesModel = themeStylesConfig.getModelForTemplate(appConfig.template.templateNumber);

            const components = stylesModel.sections
                .filter(section => section.id === sectionId)
                .reduce((groups, section) => ko.utils.arrayPushAll(groups, section.groups || []), [])
                .filter(group => !(groupId) || group.id === groupId)
                .reduce((previewComponents, group) =>
                    ko.utils.arrayPushAll(previewComponents, group.previewComponents || []), []);

            return ko.utils.arrayGetDistinctValues(components);
        }

        this.components = ko.pureComputed(() => getPreviewComponents(this.sectionId(), this.groupId()));

        function getSectionById(sectionId) {
            const stylesModel = themeStylesConfig.getModelForTemplate(appConfig.template.templateNumber);

            return ko.utils.arrayFirst(stylesModel.sections, section => section.id === sectionId);
        }

        function getGroupById(section, groupId) {
            const sectionGroups = section ? section.groups : [];

            return ko.utils.arrayFirst(sectionGroups, group => group.id === groupId);
        }

        this.sectionTitleKey = ko.pureComputed(() => {
            const section = getSectionById(this.sectionId());

            return section ? section.titleKey : '';
        });

        this.groupTitleKey = ko.pureComputed(() => {
            const group = getGroupById(getSectionById(this.sectionId()), this.groupId());

            return group ? group.titleKey || group.nameKey : '';
        });

        cxEvents.loaded.dispatch();
    }

}