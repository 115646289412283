import { bindingHandlers, utils } from 'knockout';
import { setFocusWithDelay } from 'core/a11y/service/setFocusWithDelay';
import keyCodes from 'core/config/keycodes';

/**
 * Binding used to force browser focus to move to
 * the provided element on target shift tab key event.
 *
 * @param {string} focusOn - element selector to focus on
 * @param {integer} delay - time in miliseconds after focus will be set - needed in cases where animation is fired
 *
 * @example
 *   <div id="focus-on-me-after-shift-tab"></div>
 *   <div data-bind="a11y.setFocusOnShiftTab: {focusOn: '#focus-on-me-after-shift-tab'}"
 *   <div data-bind="a11y.setFocusOnShiftTab: {focusOn: '#focus-on-me-after-shift-tab', delay: 400}"
 */
bindingHandlers['a11y.setFocusOnShiftTab'] = {
    init(element, accessor) {
        const { focusOn, delay } = accessor();

        function onKeyDown(event) {
            const wasShiftTabPressed = event.shiftKey
                && event.which === keyCodes.TAB;

            if (wasShiftTabPressed) {
                setFocusWithDelay(focusOn, delay);
            }
        }

        element.addEventListener('keydown', onKeyDown);

        utils.domNodeDisposal.addDisposeCallback(element, () => {
            element.removeEventListener('keydown', onKeyDown);
        });
    },
};