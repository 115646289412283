import router from 'app/model/router';
import 'job-details/config/routing';
import employeeConfig from 'app/model/employeeConfig';

const jobDetailsViewConfig = router.getRoute('job-details').view();

router.configure({
    'job-formatting-preview': {
        url: '/admin/job-preview/{jobId}',
        view: jobDetailsViewConfig,

        canEnter() {
            return employeeConfig.init();
        },

        enter() {
            document.body.classList.add('admin-job-preview');
        },
    },
    'job-template-preview': {
        url: '/admin/job-template-preview/{jobId}',
        view: jobDetailsViewConfig,

        canEnter() {
            return employeeConfig.init();
        },

        enter() {
            document.body.classList.add('admin-job-preview');
        },
    },
});
