import { observable } from 'knockout';
import SearchBoxAbstractViewModel from 'search/module/search-box/component/search-box-input-form/SearchBoxAbstractViewModel';
import searchEvents from 'search/config/events';
import 'cx/service/geolocation/geolocation';

export default class SearchBoxMoheganViewModel extends SearchBoxAbstractViewModel {

    constructor(params) {
        super(params);

        this.calculateAutosuggestLeftPosition = params.calculateAutosuggestLeftPosition;

        this.filterPanelVisible = observable(false);
        this.performSearch = this.performSearch.bind(this);

        searchEvents.filterPanel.toggle.add(this.filterPanelVisible);
    }

    toggleFilters() {
        searchEvents.filterPanel.toggle.dispatch(!this.filterPanelVisible());
    }

    performSearch(context, event) {
        if (event) {
            event.preventDefault();
        }

        super.performSearch();
    }

}