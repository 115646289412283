import ko from 'knockout';
import $ from 'jquery';
import keycodes from 'core/config/keycodes';

/**
 * Add keyboard navigation to element. Set focus on next/previous/upper/bottom
 * navigable element according which arrow key was pressed.
 *
 * @param {string} elements - jquery selector for navigable elements
 * @param {number} rowLength - navigable elements in a single row
 * @param {object} specialKeys - object with callbacks to handle special keys, e.g. { ESC: callbackForEsc }
 *
 * @example
 * <div data-bind="a11y.keyboardNavigation: { rowLength: 1
 *                     elements: '.navigable-element',
 *                     specialKeys: { KEYCODE: keycodeCallback }}">
 *     <!-- child elements with 'navigable-element' class, between which user can navigate -->
 * </div>
 */

ko.bindingHandlers['a11y.keyboardNavigation'] = {
    init(container, accessor) {
        const $container = $(container);
        const { rowLength } = accessor();
        const specialKeys = accessor().specialKeys || {};

        Object.keys(specialKeys).forEach((key) => {
            $container.keydown((event) => {
                if (event.which === keycodes[key]) {
                    event.preventDefault();
                    event.stopPropagation();
                    specialKeys[key]();
                }
            });
        });

        function resolveFocus(event) {
            const $elements = $container.find(accessor().elements);
            let index = $elements.index(this);
            let eventHandled = true;

            switch (event.which) {
                case keycodes.LEFT_ARROW:
                    index = Math.max(0, index - 1);
                    break;
                case keycodes.RIGHT_ARROW:
                    index = Math.min($elements.length - 1, index + 1);
                    break;
                case keycodes.DOWN_ARROW:
                    index = Math.min($elements.length - 1, index + rowLength);
                    break;
                case keycodes.UP_ARROW:
                    index = Math.max(0, index - rowLength);
                    break;
                default:
                    eventHandled = false;
            }

            if (eventHandled) {
                event.preventDefault();
                $elements.eq(index).focus();
            }
        }

        $container.on('keydown', accessor().elements, resolveFocus);

        ko.utils.domNodeDisposal.addDisposeCallback(container, () => {
            $container.off('keydown', resolveFocus);
        });
    },
};
