import './templates/templates';
import 'ce-common/component/dot-spinner/component';

import { components } from 'knockout';

import ViewModel from './ApplyFlowSubmissionChecklistViewModel';
import template from './apply-flow-submission-checklist.html';

components.register('apply-flow-submission-checklist', {
    viewModel: ViewModel,
    template,
});
