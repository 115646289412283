import SearchQueryBuilder from './SearchQueryBuilder';
import searchEvents from 'search/config/events';

function _getHandlerName(paramName) {
    return `with${paramName.substr(0, 1).toUpperCase()}${paramName.substr(1)}`;
}

export default class SearchHandler {

    constructor() {
        searchEvents.query.params.add(this.updateParams.bind(this));
    }

    initialize(params) {
        this._queryBuilder = new SearchQueryBuilder(params);
    }

    updateParams(params) {
        const handledParameters = this._getHandledParameters();

        Object.keys(params)
            .filter(param => handledParameters.indexOf(param) > -1)
            .forEach((handledParam) => {
                this._queryBuilder[_getHandlerName(handledParam)](params[handledParam]);
            });
    }

    /**
     * Build query parameters object
     * @returns {Object} query parameters
     */
    buildQuery() {
        return this._queryBuilder.build();
    }

    /**
     * @abstract
     * @protected
     * @return {[string]} handled parameter names
     */
    _getHandledParameters() {
        throw new Error('Not implemented!');
    }

}