import ko from 'knockout';
import Hammer from 'hammerjs';


function handleSwipe(event, callback) {
    if (!('swipeInsensitive' in event.target.dataset)) {
        event.srcEvent.stopPropagation();
        callback();
    }
}

/**
 * Fires a callback function after swiping on current element.
 *
 * @param {function} right  - action to call after swiping right
 * @param {function} left   - action to call after swiping left
 * @param {function} up     - action to call after swiping up
 * @param {function} down   - action to call after swiping down
 *
 * @example
 * <aside class="search-filters-container" data-bind="swipe: {left: closeFilters}">
 *
 * @example
 * <div class="media-tile-overlay"
 *   data-bind="swipe: {left: $parent.onNextButtonClicked, right: $parent.onPreviousButtonClicked}">
 */
ko.bindingHandlers.swipe = {
    init(element, accessor) {
        const hammer = new Hammer(element, {
            inputClass: Hammer.TouchInput,
            cssProps: {
                userSelect: true,
            },
        });

        if (accessor().right) {
            hammer.on('swiperight', (event) => {
                handleSwipe(event, accessor().right);
            });
        }

        if (accessor().left) {
            hammer.on('swipeleft', (event) => {
                handleSwipe(event, accessor().left);
            });
        }

        if (accessor().up) {
            hammer.on('swipeup', (event) => {
                handleSwipe(event, accessor().up);
            });
        }

        if (accessor().down) {
            hammer.on('swipedown', (event) => {
                handleSwipe(event, accessor().down);
            });
        }

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            hammer.destroy();
        });
    },
};