import ko from 'knockout';
import $ from 'jquery';

const SCROLL_DIRECTIONS = {
    horizontal: 'scrollLeft',
    vertical: 'scrollTop',
};

const OPERATORS = {
    forward: '+=',
    backward: '-=',
};

function getScrollOptions(params, $firstChild) {
    const scrollAmount = params.axis === 'horizontal' ? $firstChild.width() : $firstChild.height();

    const scrollOptions = {};

    scrollOptions[SCROLL_DIRECTIONS[params.axis]] = OPERATORS[params.direction] + scrollAmount;

    return scrollOptions;
}

let $scrollable;
let $firstChild;

/**
 * A click handler that allows programatically scrolling container in various directions and axes.
 * On click it scrolls the container by width (or height if scrolling is vertical) of first child of
 * the container.
 *
 * @param scrollable {node} - element that should be scrolled
 * @param axis {string} - axis of scrolling, if 'horizontal' then scrolling is done
 *                        horizontal, otherwise vertical
 * @param direction {string} - direction of scrolling, accepts two values 'forward' and 'backward'.
 * @listens click
 * @example
 * <button data-bind="scroll: {scrollable: '.big-container', direction: 'backward', axis: 'horizontal'}">
 *      Scroll Left
 * </button>
 * <ul class="big-container">
 *     <li>Child</li>
 *     <!-- ...lots of children -->
 * </ul>
 */
ko.bindingHandlers.scroll = {
    init(element, params) {
        $scrollable = $(params().scrollable);
        $firstChild = $scrollable.children().eq(0);
    },

    update(element, params) {
        function scrollContainer() {
            $scrollable.animate(getScrollOptions(params(), $firstChild), 200);
        }

        element.removeEventListener('click', scrollContainer);
        element.addEventListener('click', scrollContainer);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            element.removeEventListener('click', scrollContainer);
        });
    },
};