import ko from 'knockout';
import router from 'app/model/router';
import searchEvents from 'search/config/events';
import screenInfo from 'cx/model/screenInfo';

export default class FilterPanelMoheganViewModel {

    constructor() {
        this.facets = ko.observableArray([]);
        this.isSmallScreen = screenInfo.isSmall;

        this.isFilterPanelVisible = ko.observable(false);

        searchEvents.results.facetsUpdated.add(this.facets);

        this.closeFilters = searchEvents.filterPanel.toggle.dispatch.bind(null, false);

        searchEvents.filterPanel.toggle.add(this.isFilterPanelVisible);

        searchEvents.filterPanel.toggle.add((isVisible) => {
            if (isVisible) {
                router.go('search', {
                    jobId: null,
                });
            }
        });
    }

    clearFacets() {
        searchEvents.query.clearFacets.dispatch();
        searchEvents.query.filterSearch.dispatch(true);
    }

}