import ko from 'knockout';
import copyAbilityResolver from 'job-details/component/copy-link/service/copyAbilityResolver';


/**
 * Copies text to clipboard from indicated input field
 *
 * @param {string} inputSelector - css selector for target input field
 *
 * @example
 * <input type="text" readonly class="job-link" data-bind="value: jobLink">
 * <button data-bind="copyToClipboard: {inputSelector: '.job-link'}"></button>
 */
ko.bindingHandlers.copyToClipboard = {
    init(element, accessor) {
        const target = document.querySelector(accessor().inputSelector);

        function copy() {
            target.focus();
            target.setSelectionRange(0, target.value.length);

            if (copyAbilityResolver.canCopyProgramatically()) {
                document.execCommand('copy');
                target.setSelectionRange(0, 0);
                element.focus();
            }
        }

        element.addEventListener('click', copy);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            element.removeEventListener('click', copy);
        });
    },
};