import '../config/routing';
import '../component/cs-application-tile/component';
import '../component/cs-application-draft-tile/component';
import '../component/cs-applications/component';
import '../component/cs-applications-confirm-all/component';
import '../component/cs-header-button/component';
import '../component/cs-confirm-dialog/component';
import '../component/application-withdraw-dialog/component';
import '../component/application-draft-delete-dialog/component';
import '../component/cs-job-alert/component';
import '../component/cs-talent-community/component';
import '../component/cs-application-context-menu/component';
import '../component/cs-application-actions/component';
import '../component/cs-dropdown-multiselect-pill-preference/component';
import '../component/cs-search-multiselect-pill-preference/component';
import '../component/cs-delete-profile/component';
import '../component/cs-update-profile/component';
import '../module/interview-scheduling/config/module';

import cssShortTokenViewModel from '../CandidateSelfServiceShortTokenViewModel';
import cssActionTemplate from '../candidate-self-service-action.html';

import { components } from 'knockout';

import { createViewModel } from '../service/cssViewModelFactory';

import template from '../candidate-self-service.html';

components.register('candidate-self-service', {
    viewModel: {
        createViewModel,
    },
    template,
});

components.register('candidate-self-service-action', {
    viewModel: cssShortTokenViewModel,
    template: cssActionTemplate,
});