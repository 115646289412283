import './binding/loopNavigation';
import './binding/toggleSubmenu';
import './binding/hideableAside';

import { components } from 'knockout';

import ViewModel from './CxHeaderViewModel';
import template from './cx-header.html';

components.register('cx-header', { viewModel: ViewModel, template });
