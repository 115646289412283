import ko from 'knockout';
import screenInfo from 'cx/model/screenInfo';

const LARGE_UP_CLASS = 'desktop-screen';
const LARGE_DOWN_CLASS = 'large-down-screen';
const MEDIUM_UP_CLASS = 'medium-up-screen';
const SMALL_CLASS = 'mobile-screen';

/**
 * Applies screen size related classes to the element.
 *
 * @example
 * <div data-bind="cssScreenType"></div>
 */
ko.bindingHandlers.cssScreenType = {
    init(element) {
        const screenType = ko.computed(() => {
            const isLargeUpScreen = screenInfo.isLargeUp();
            const isLargeDownScreen = !isLargeUpScreen;

            ko.utils.toggleDomNodeCssClass(element, SMALL_CLASS, screenInfo.isSmall());
            ko.utils.toggleDomNodeCssClass(element, MEDIUM_UP_CLASS, screenInfo.isMediumUp());
            ko.utils.toggleDomNodeCssClass(element, LARGE_DOWN_CLASS, isLargeDownScreen);
            ko.utils.toggleDomNodeCssClass(element, LARGE_UP_CLASS, isLargeUpScreen);
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            screenType.dispose();
        });
    },
};