import router from 'app/model/router';

export default class CampaignThankYouViewModel {

    goToParentRoute() {
        const route = router.route();

        return router.go(route.parent.id);
    }

}