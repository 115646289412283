import 'apply-flow/module/agreements/component/agreement-dialog/component';
import 'apply-flow/module/agreements/component/legal-disclaimer/component';
import 'apply-flow/module/agreements/component/opt-in/component';
import 'apply-flow/module/agreements/component/job-alerts-opt-in/component';

import metadataConfig from 'apply-flow/config/metadata';
import { components } from 'knockout';

import ViewModel from '../AgreementsViewModel';
import template from '../agreements.html';

components.register('agreements', { viewModel: ViewModel, template });

metadataConfig.registerBlock('ORA_AGREEMENTS', {
    component: 'agreements',
});
