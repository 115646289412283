import ko from 'knockout';
import screenInfo from 'cx/model/screenInfo';
import searchBoxEvents from 'search/module/search-box/component/search-box-results-page/config/events';


function SearchBoxResultsPageViewModel() {
    this.isSearchBoxVisible = ko.observable(screenInfo.isMediumUp());
    this.isSmallScreen = screenInfo.isSmall;

    searchBoxEvents.toggleSearchBox.add(this.isSearchBoxVisible);
    this.close = searchBoxEvents.toggleSearchBox.dispatch.bind(this, false);
}

export default SearchBoxResultsPageViewModel;