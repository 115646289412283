import 'search/module/location/component/location-search/component';
import 'search/module/location/component/radius-search/component';
import 'cx/binding/disableBodyScroll';
import '../component/zipcode-search/component';
import '../component/geolocation-search/component';
import '../component/country-search/component';
import '../module/mode-menu/config/module';


import { components } from 'knockout';

import ViewModel from '../LocationBarViewModel';
import template from '../location-bar.html';

components.register('location-bar', { viewModel: ViewModel, template });
