import ko from 'knockout';

function getBackgroundUrlFromElement(element) {
    const { backgroundImage } = window.getComputedStyle(element, false);

    if (backgroundImage) {
        const backgroundImageMatch = backgroundImage.match(/url\("?([^"]+)"?\)/);

        if (backgroundImageMatch) {
            return backgroundImageMatch[1];
        }
    }

    return '';
}

function updateImageSource(image, source) {
    const backgroundImage = getBackgroundUrlFromElement(source);

    if (backgroundImage) {
        image.setAttribute('src', backgroundImage);
    } else {
        image.removeAttribute('src');
    }

    return Boolean(backgroundImage);
}

/**
 * Applies to <img /> only. Specified element's background image path is applied as src to the image.
 *
 * @param {string} value - selector of an element with background image
 * @param {observable} observer - true or false depending on backgorund image availability
 *
 * @example
 * <span class="some-class"></span>
 * <img data-bind="srcFromBackground: { source: '.some-class', observer: observer }">
 */
ko.bindingHandlers.srcFromBackground = {
    init(element, value) {
        const params = value();

        const source = document.querySelector(params.source);
        const { observer } = params;

        const observerSub = observer.subscribe(() => {
            observer(updateImageSource(element, source));
        });

        observer(updateImageSource(element, source));

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            observerSub.dispose();
        });
    },
};