import { observable } from 'knockout';
import router from 'app/model/router';
import i18n from 'core/i18n/i18n';
import { noOperation } from 'core/utils/noOperation';
import dictionaryService from 'apply-flow/service/dictionary';
import geolocation from 'cx/service/geolocation/geolocation';
import searchEvents from 'search/config/events';
import { ojTranslations } from 'cx/service/ojTranslations';

class CountrySearchViewModel {

    constructor() {
        this.options = observable([]);
        this.country = observable();
        this.isDisabled = observable(false);
        this.ojTranslations = ojTranslations;

        this.placeholder = i18n('location-bar.country-placeholder');

        this._clearSignal = searchEvents.clear.add(this._onClear.bind(this));

        this.countrySubscription = this.country.subscribe((value) => {
            searchEvents.query.params.dispatch({
                countryCode: value,
            });
        });

        this._setParamsFromRouter();
        this._initialize();
    }

    async _initialize() {
        await this._initializeOptions();

        if (!this.country()) {
            await this._prepopulateCountry();
        }
    }

    async _initializeOptions() {
        this.isDisabled(true);

        try {
            this.options(await this._getOptions());
        } finally {
            this.isDisabled(false);
        }
    }

    async _prepopulateCountry() {
        this.isDisabled(true);

        try {
            const geolocationResponse = await geolocation.query();

            this.country(geolocationResponse.countryCode);
        } catch (error) {
            noOperation(error);
        } finally {
            this.isDisabled(false);
        }
    }

    async _getOptions() {
        const countries = await dictionaryService.get(dictionaryService.CODES.countries);

        return countries.map(country => ({
            label: country.territoryShortName,
            value: country.territoryCode,
        }));
    }

    _onClear() {
        this.country(null);
    }

    _setParamsFromRouter() {
        const { query } = router.routeParams();

        if (query) {
            this.country(query.workLocationCountryCode);
        }
    }

    dispose() {
        this.countrySubscription.dispose();
        this._clearSignal.detach();
    }

}

export default CountrySearchViewModel;
