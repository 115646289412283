import { observable, observableArray, pureComputed } from 'knockout';
import appConfig from 'app/model/config';
import searchEvents from 'search/config/events';
import themeEvents from 'cx/module/site-editor/config/events';
import cxEvents from 'cx/config/events';
import linksConfig from 'core/config/links';
import pageLinksProvider from 'app/service/pageLinksProvider';

export default class CxFooterViewModel {

    constructor() {
        const { footerMode } = appConfig;

        this.templateFooter = observable(footerMode === 1 || footerMode === 4);
        this.links = observableArray(pageLinksProvider.getFooterPageLinks());

        this.jobDetailsPreviewMode = observable(false);
        this.filterPanelVisible = observable(false);
        this.inViewport = observable();

        this.mobileLinks = pureComputed(() => {
            const mobileLinks = [];

            const serchForMobileLinks = (link) => {
                if (link.visibleOnMobile) {
                    mobileLinks.push(link);
                }

                if (link.sublinks) {
                    link.sublinks.forEach(serchForMobileLinks);
                }
            };

            this.links().forEach(serchForMobileLinks);

            return mobileLinks.slice(0, linksConfig.footer.MOBILE_LINKS_LIMIT);
        });

        this._filterPanelToggleSignal = searchEvents.filterPanel.toggle.add(this.filterPanelVisible);
        this._jobDetailsRenderedPreviewSignal = searchEvents.jobDetails.renderedPreview.add(this.jobDetailsPreviewMode);

        this._footerUpdatedSignal = themeEvents.templateFooterUpdated.add((use) => {
            this.templateFooter(use);
        });

        this.inViewport.subscribe(
            cxEvents.cxFooterInViewportChanged.dispatch.bind(cxEvents.cxFooterInViewportChanged),
        );

        this.isFooterVisible = pureComputed(() => this.links().length);
    }

    dispose() {
        this._filterPanelToggleSignal.detach();
        this._jobDetailsRenderedPreviewSignal.detach();
        this._footerUpdatedSignal.detach();
    }

}
