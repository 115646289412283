import ko from 'knockout';
import $ from 'jquery';
import themeEvents from 'cx/module/site-editor/config/events';
import cxHeaderEvents from 'cx/component/cx-header/config/events';

const CUSTOM_HEADER_ID = 'custom-header-container';
const INIT_VALUE_STORAGE_KEY = 'adjustInitValue';

function getStyleValue($element, styleProperty) {
    return parseFloat($element.css(styleProperty).replace('px', ''));
}

function buildStorageKey(styleProperty) {
    return [INIT_VALUE_STORAGE_KEY, styleProperty].join(':');
}

function getStyleBaseValue($element, styleProperty) {
    const storageKey = buildStorageKey(styleProperty);
    let initialStyleValue = $element.data(storageKey);

    if (!initialStyleValue) {
        initialStyleValue = getStyleValue($element, styleProperty);
        $element.data(storageKey, initialStyleValue);
    }

    return initialStyleValue;
}

function calcHeight($element, styleProperty, customHeaderHeight) {
    const initialStyleValue = getStyleBaseValue($element, styleProperty);
    const initialSubtractHeight = window.innerHeight - initialStyleValue;
    const newValue = `calc(var(--viewport-height) - ${initialSubtractHeight + customHeaderHeight}px)`;

    $element.css(styleProperty, newValue);
}

function setHeight($element, styleProperties, customHeaderHeight) {
    let params = styleProperties;

    if (typeof params === 'string') {
        params = [params];
    }

    params.forEach((styleProperty) => {
        calcHeight($element, styleProperty, customHeaderHeight);
    });
}

function resetHeight($element, styleProperties) {
    let params = styleProperties;

    if (typeof params === 'string') {
        params = [params];
    }

    params.forEach((styleProperty) => {
        $element.css(styleProperty, '');
        $element.data(buildStorageKey(styleProperty), '');
    });
}

const handlers = {

    'set-top': {
        apply($element, params, customHeaderInfo) {
            $element.css('top', customHeaderInfo.height);
        },
        reset($element) {
            $element.css('top', '');
        },
    },

    'add-top': {
        apply($element, params, customHeaderInfo) {
            const initialStyleValue = getStyleBaseValue($element, 'top');

            $element.css('top', initialStyleValue + customHeaderInfo.height);
        },
        reset($element) {
            $element.css('top', '');
            $element.data(buildStorageKey('top'), '');
        },
    },

    'calc-top': {
        apply($element, params, customHeaderInfo) {
            const initialStyleValue = getStyleBaseValue($element, 'top');

            $element.css('top', initialStyleValue + customHeaderInfo.visibleHeight);
        },
        reset($element) {
            $element.css('top', '');
            $element.data(buildStorageKey('top'), '');
        },
    },

    'calc-height': {
        apply($element, params, customHeaderInfo) {
            setHeight($element, params, customHeaderInfo.visibleHeight);
        },
        reset: resetHeight,
    },

    'fixed-height': {
        apply($element, params, customHeaderInfo) {
            setHeight($element, params, customHeaderInfo.height);
        },
        reset() {},
    },

    css: {
        apply($element, className) {
            $element.addClass(className);
        },
        reset($element, className) {
            $element.removeClass(className);
        },
    },

};

function applyOperations(element, bindingParams, customHeaderHeight, customHeaderVisibleHeight) {
    const $element = $(element);

    const customHeaderInfo = {
        height: customHeaderHeight,
        visibleHeight: customHeaderVisibleHeight,
    };

    Object.keys(bindingParams)
        .filter(paramName => Boolean(handlers[paramName]))
        .forEach((operationName) => {
            const params = bindingParams[operationName];
            const operationHandler = handlers[operationName];

            if (customHeaderInfo.visibleHeight > 0) {
                operationHandler.apply($element, params, customHeaderInfo);
            } else {
                operationHandler.reset($element, params);
            }
        });
}

function getCustomHeaderHeight() {
    const customHeaderElement = document.getElementById(CUSTOM_HEADER_ID);

    if (!customHeaderElement) {
        return 0;
    }

    return parseFloat($(customHeaderElement).outerHeight(false));
}


/**
 * Applies to the element predefined operations regarding styling changes based on the custom header's height.
 *
 * @param {string} comma-delimited list of operations to apply to the element regarding custom header height
 *
 * @example
 * <div data-bind="adjustToCustomHeader: { '[operationName]': '[operation params]' }"></div>
 */
ko.bindingHandlers.adjustToCustomHeader = {
    init(element, valueAccessor) {
        function adjust() {
            const customHeaderHeight = getCustomHeaderHeight();

            applyOperations(element, valueAccessor(), customHeaderHeight, customHeaderHeight);
        }

        function onCustomHeaderVisibilityChange(customHeaderVisibleHeight) {
            applyOperations(element, valueAccessor(), getCustomHeaderHeight(), customHeaderVisibleHeight);
        }

        themeEvents.customHeaderHtmlUpdated.add(adjust);
        cxHeaderEvents.customHeaderVisibilityChanged.add(onCustomHeaderVisibilityChange);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            themeEvents.customHeaderHtmlUpdated.remove(adjust);
            cxHeaderEvents.customHeaderVisibilityChanged.remove(onCustomHeaderVisibilityChange);
        });

        adjust();
    },
};