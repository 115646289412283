import ko from 'knockout';

const SUGGESTION_DATA = [
    'Consumer Manager',
    'Client Success Manager',
    'Communication Specialist',
    'Customer Care',
    'Customer Support Specialist',
];

export default class AutocompleteViewModel {

    constructor() {
        this.model = ko.observable('c');
    }

    suggestions() {
        return Promise.resolve(SUGGESTION_DATA);
    }

}