import AiJobsSearchAbstractViewModel from '../search-ai-jobs/AiJobsSearchAbstractViewModel';
import searchService from 'search/service/search';

export default class SearchSimilarViewModel extends AiJobsSearchAbstractViewModel {

    getJobs(params) {
        return searchService.searchSimilarJobs({ params, similarJobId: this.requisitionId });
    }

    getHeaderLabel() {
        return 'search.search-similar.header';
    }

}