import { components } from 'knockout';

import metadataConfig from '../../../config/metadata';
import MetadataRepository from '../service/Metadata';
import '../component/extra-information-tile-form/component';

import ViewModel from '../ExtraInformationViewModel';
import template from '../extra-information.html';

components.register('extra-information', { viewModel: ViewModel, template });

metadataConfig.registerBlock('ORA_JA_EXTRA_INFO', {
    component: 'extra-information',
    repository: MetadataRepository,
    requireBlockProperties: true,
});

