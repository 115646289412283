import SearchHandler from './SearchHandler';
import searchEvents from 'search/config/events';

const HANDLED_PARAMS = [
    'facet',
    'sortOption',
];

export default class FacetSearchHandler extends SearchHandler {

    constructor() {
        super();

        searchEvents.query.clearFacets.add(() => {
            this._queryBuilder
                .withoutSortOption()
                .withoutFacets();
        });

        searchEvents.query.filterSearch.add(() => {
            this._queryBuilder.withoutOffset();
        });

        searchEvents.query.offsetSearch.add((offset) => {
            this._queryBuilder.withOffset(offset);
        });

        searchEvents.pageLoaded.add(() => {
            this._queryBuilder.withoutOffset();
        });
    }

    /**
     * @override
     * @private
     */
    _getHandledParameters() {
        return HANDLED_PARAMS;
    }

}