import router from 'app/model/router';
import appConfig from 'app/model/config';

const viewConfig = {
    mohegan: {
        'cx-header': 'favourite-navbar-btn',
        'cx-header-mobile': 'favourite-navbar-btn',
        'cx-header-panel': 'favourite-panel',
        main: 'search',
        'search-header': 'search-box-results-page',
        'search-main': 'search-results',
        'search-aside': 'job-details',
    },
};

router.configure({
    'job-details': {
        parent: 'cx',
        url: '/job/{jobId}:?query:',
        view: viewConfig[appConfig.template.templateNumber],
    },
});
