import { components } from 'knockout';

import metadataConfig from 'apply-flow/config/metadata';
import MetadataRepository from 'apply-flow/module/profile-import/service/Metadata';
import 'apply-flow/module/profile-import/config/routing';
import '../binding/awliWidget';
import '../component/awli-confirm/component';

import ViewModel from '../ProfileImportViewModel';
import template from '../profile-import.html';

components.register('profile-import', { viewModel: ViewModel, template });

metadataConfig.registerBlock('ORA_PROFILE_IMPORT', {
    component: 'profile-import',
    repository: MetadataRepository,
    isPresectionBlock: true,
});
