import { observable } from 'knockout';

function CxDialogViewModel(params) {
    this.baseClass = params.baseClass;
    this.isVisible = params.isVisible;
    this.refresh = params.refresh || observable(false);
    this.title = params.title || '';
    this.onClose = params.onClose;
    this.cancelBehavior = params.cancelBehavior || 'icon';

    this.nodes = {};
}

export default {
    createViewModel(params, componentInfo) {
        const viewModel = new CxDialogViewModel(params);

        componentInfo.templateNodes
            .filter(node => node.tagName)
            .forEach((node) => {
                const nodeName = node.tagName.toLowerCase();

                viewModel.nodes[nodeName] = node.childNodes;
            });

        return viewModel;
    },
};
