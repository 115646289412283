import { pureComputed } from 'knockout';
import GeoAutosuggestFormElementViewModel from './GeoHierarchyAutosuggestFormElementViewModel';

export default class GeoHierarchyAutosuggestFormElementMoheganViewModel extends GeoAutosuggestFormElementViewModel {

    constructor(...args) {
        super(...args);

        this.placeholder = pureComputed(this._computePlaceholder, this);
    }

    _computePlaceholder() {
        const isEmpty = this.isEmpty();
        const hasFocus = this.hasFocus();
        const isLoading = this.element.isLoading();

        if (!hasFocus || isEmpty || isLoading) {
            return ' ';
        }

        return this.defaultPlaceholder;
    }

}
