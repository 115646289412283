import ko from 'knockout';
import appConfig from 'app/model/config';
import cxHeaderEvents from 'cx/component/cx-header/config/events';
import screenInfo from 'cx/model/screenInfo';
import deviceDetector from 'core/device-detector/deviceDetector';
import pageLinksProvider from 'app/service/pageLinksProvider';
import signedCandidate from 'candidate-verification/model/signedCandidate';

export default class CxHeaderViewModel {

    constructor(params = {}) {
        this.isSignedIn = signedCandidate.isSignedIn;
        this.isCustomContent = Boolean(params.isCustomContent);
        this.links = ko.observableArray(pageLinksProvider.getHeaderPageLinks());
        this.isOpen = ko.observable(false);
        this.isDesktopScreen = screenInfo.isLargeUp;
        this.classSkipTo = 'skip-navigation-link-cx-header';

        this.isMenuHidden = ko.pureComputed(() => {
            const isDesktopScreen = this.isDesktopScreen();
            const isOpen = this.isOpen();

            return (isDesktopScreen) ? false : !isOpen;
        });

        this.isVisible = ko.observable(true);

        this.isSafari9 = deviceDetector.isSafari9();
        this.displayLanguageSelector = appConfig.languages.length > 1;

        this._openMenuSignal = cxHeaderEvents.openMenu.add(() => this.isOpen(true));
        this._closeMenuSignal = cxHeaderEvents.closeMenu.add(() => this.isOpen(false));
        this._toggleHeaderSignal = cxHeaderEvents.toggleHeader.add(visible => this.isVisible(visible));
    }

    toggle() {
        if (this.isOpen()) {
            cxHeaderEvents.closeMenu.dispatch();
        } else {
            cxHeaderEvents.openMenu.dispatch();
        }

        return true;
    }

    dispose() {
        this._openMenuSignal.detach();
        this._closeMenuSignal.detach();
        this._toggleHeaderSignal.detach();
    }

}
