import ko from 'knockout';

const DATA_ATTRIBUTE = 'keepInViewId';

function findMatchingElement(elements, id) {
    for (let i = 0; i < elements.length; i++) {
        if (elements[i].dataset[DATA_ATTRIBUTE] === id) {
            return elements[i];
        }
    }

    return null;
}

function isElementVisibleOnScreen(element) {
    const viewTop = document.body.scrollTop;
    const viewBottom = viewTop + window.innerHeight;
    const elementTop = element.offsetTop;
    const elementBottom = elementTop + element.clientHeight;

    return elementBottom <= viewBottom && elementTop >= viewTop;
}

/**
 * Used on container which contains elements, which can be selected. The selected element is kept on screen by
 * this binding by setting correct scrollTop amount.
 *
 * @param {ko.observable} selectedId - id of selected element, value of element's data-keep-in-view-id attribute.
 * @param {string} [scrollable=document.body] - selector indicating container that should be scrolled
 * @param {int} [offset=0] - offset from top of the page
 *
 * @example
 *
 * class VM {
 *    this.selectedItemId = ko.observable();
 * }
 *
 * <ul keepInView: {selectedId: selectedItemId, offset: 80}">
 *     <li data-keep-in-view-id="someid1"></li>
 *     <li data-keep-in-view-id="someid2"></li>
 *     <!-- ...lots of items --->
 * </ul>
 *
 * When selectedItemId changes to 'someid2', and 'someid2' is not visible on screen, the binding will cause
 * document body to set scroll value to the point where 'someid2' is visible.
 */
ko.bindingHandlers.keepInView = {
    init(container, accessor) {
        const scrollable = accessor().scrollable ? document.querySelector(accessor().scrollable) : document.body;
        const offset = accessor().offset || 0;

        const subscription = accessor().selectedId.subscribe((id) => {
            if (!id) {
                return;
            }

            const elementToShow = findMatchingElement(container.children, id);

            if (!elementToShow) {
                return;
            }

            if (!isElementVisibleOnScreen(elementToShow)) {
                scrollable.scrollTop = elementToShow.offsetTop - offset;
            }
        });

        ko.utils.domNodeDisposal.addDisposeCallback(container, subscription.dispose.bind(subscription));
    },
};