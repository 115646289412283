import ko from 'knockout';
import screenInfo from 'cx/model/screenInfo';

export default class MediaTilesMoheganViewModel {

    constructor(params) {
        this.tiles = params.tiles().filter(({ type }) => type() !== 'Map');
        this.screenInfo = screenInfo;

        this.viewState = ko.observable('collapsed');
        this.currentTile = ko.observable(null);
        this.lastPlayedTileIndex = ko.observable();

        this.play = (tile) => {
            this.viewState('playing');
            this.currentTile(tile);
            this.lastPlayedTileIndex(this.tiles.indexOf(tile));
        };

        this.collapse = () => {
            this.currentTile(null);
            this.viewState('collapsed');
            this.lastPlayedTileIndex.notifySubscribers();
        };

        this.currentTileIndex = ko.observable(0);

        this.onMediaTileClicked = (clickedTileIndex, tile) => {
            this.play(tile);

            if (clickedTileIndex === this.currentTileIndex() - 1) {
                this.onPreviousButtonClicked();
            }

            if (clickedTileIndex === this.currentTileIndex() + 1) {
                this.onNextButtonClicked();
            }
        };

        this.onPreviousButtonClicked = () => {
            if (this.currentTileIndex() > 0) {
                this.currentTileIndex(this.currentTileIndex() - 1);
            }
        };

        this.onNextButtonClicked = () => {
            if (this.currentTileIndex() < this.tiles.length - 1) {
                this.currentTileIndex(this.currentTileIndex() + 1);
            }
        };
    }

}