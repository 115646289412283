import router from 'app/model/router';
import talentCommunityEvents from './events';
import { isTCOptInEnabled } from 'app/service/isTCOptInEnabled';

router.configure({
    'talent-community': {
        parent: 'cx',
        url: '/tc-join:?query:',
        enter() {
            talentCommunityEvents.show.dispatch();
        },
        canEnter() {
            return isTCOptInEnabled()
                ? Promise.resolve()
                : Promise.reject({ redirectTo: 'home-page' });
        },
    },
});
