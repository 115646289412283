import ko from 'knockout';
import router from 'app/model/router';
import SearchResultsAbstractViewModel from './SearchResultsAbstractViewModel';
import searchEvents from 'search/config/events';

export default class MoheganSearchResultViewModel extends SearchResultsAbstractViewModel {

    constructor(params) {
        super(params);

        this.isJobDetailsContainerClosing = ko.observable(false);

        this.selectedJobId.subscribe((selectedJobId) => {
            if (selectedJobId) {
                searchEvents.filterPanel.toggle.dispatch(false);
            }
        });

        this.filterPanelVisible = ko.observable(false);
        searchEvents.filterPanel.toggle.add(this.filterPanelVisible);

        this.isJobHalfView = ko.pureComputed(() => {
            const route = router.route();

            return route.id === 'job-preview' || (route.parent && route.parent.id === 'job-preview');
        });

        this.onJobDetailsClosing = (isClosing) => {
            this.isJobDetailsContainerClosing(isClosing);
        };

        searchEvents.jobDetails.isClosing.add(this.onJobDetailsClosing);

        this.isJobFullView = ko.pureComputed(() => !this.isJobHalfView() && this.selectedJobId());
    }

    dispose() {
        super.dispose();
        searchEvents.jobDetails.isClosing.remove(this.onJobDetailsClosing);
        searchEvents.filterPanel.toggle.remove(this.filterPanelVisible);
    }

}