import { components, contextFor } from 'knockout';

import CopyLinkViewModel from './CopyLinkViewModel';
import template from './copy-link.html';

components.register('copy-link', {
    viewModel: {
        createViewModel(params, componentInfo) {
            const context = contextFor(componentInfo.element);

            return new CopyLinkViewModel(params, context.$lang);
        },
    },
    template,
});